import React, { memo } from 'react';
import { Skeleton } from '@uxui/skeleton-loader';

export interface SkeletonTextBlockProps {
  blocks?: string[];
}

const SkeletonTextBlock = memo(
  ({
    blocks = ['200px', '300px', '250px', '100px'],
  }: SkeletonTextBlockProps) => {
    return (
      <div style={{ fontSize: 0 }}>
        {blocks.map((block, index) => (
          <Skeleton
            width={block}
            height="16px"
            key={index}
            className="mb-3 block"
          />
        ))}
      </div>
    );
  },
  () => true,
);

export default SkeletonTextBlock;
