import React, { Suspense } from 'react';
import ROUTES from 'constants/routes';
import { RouteComponentProps, navigate } from '@reach/router';
import { SiderMenu } from '@aha/ui';
import { NavItemProps } from '@aha/ui/src/components/SiderMenu';
import useSelectDashboardHotel from 'containers/Dashboard/useSelectDashboardHotel';
import { formatNestedRoutePath } from '@aha/utils';
import SettingPaneLoader from 'components/Loaders/SettingPaneLoader';
import SiderPane from 'components/SiderPane';

const navItems: NavItemProps[] = [
  {
    id: 'sidebar.menu.budget',
    name: 'Budget',
    link: ROUTES.HOTELS_ID_SETTING_BUDGET,
  },
  {
    id: 'sidebar.menu.rate',
    name: 'Best Available Rate',
    link: ROUTES.HOTELS_ID_SETTING_BEST_RATE,
  },
  {
    id: 'sidebar.menu.compsets',
    name: 'Compsets',
    link: ROUTES.HOTELS_ID_SETTING_COMPSETS,
  },
  {
    id: 'sidebar.menu.events',
    name: 'Events',
    link: ROUTES.HOTELS_ID_SETTING_EVENTS,
  },
];

const HotelSettingsLayout: React.FC<RouteComponentProps> = ({
  children,
  location,
  path,
}) => {
  const { pathname } = location!;
  const { hotelId, hotelRefCode, hotel } = useSelectDashboardHotel();

  const modifiedChildren = React.useMemo(
    // @ts-ignore
    () => formatNestedRoutePath(children, path),
    [children],
  );

  // TODO: should not be like this
  if (!hotel) {
    return null;
  }

  const mappedItems = navItems.map(item => ({
    ...item,
    link: item.link.replace(':hotelRefCode', hotelRefCode),
  }));

  const selectedKey = (
    mappedItems.filter(({ link }) => pathname.startsWith(link))[0] || {}
  ).link;

  return (
    <div className="p-2">
      <SiderMenu
        pathname={pathname}
        navItems={mappedItems}
        onItemClick={item => navigate(item.link)}
        selectedKey={selectedKey}
        defaultSelectedKeys={[pathname]}
        height="calc(100vh - 75px) !important"
      >
        <SiderPane>
          <Suspense fallback={<SettingPaneLoader />}>
            {modifiedChildren}
          </Suspense>
        </SiderPane>
      </SiderMenu>
    </div>
  );
};

export default HotelSettingsLayout;
