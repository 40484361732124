import React, { useEffect, useState, useMemo } from 'react';
import SiderPane from 'components/SiderPane';
import { RouteComponentProps, Redirect, navigate } from '@reach/router';
import ROUTES from 'constants/routes';
import { formatRoute, showSuccessNotification } from '@aha/utils';
import { Button, SkeletonTable } from '@aha/ui';
import BookingWindowForm from 'components/BookingWindowsForm';
import { updateLocationBookingSetting } from 'containers/LocationSettingBookingWindow/actions';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import {
  ViewmodelBookingWindow,
  BookingwindowBookingWindowListResponse,
  BookingwindowBookingWindowDetail,
} from 'types/rms-schema';
import { useInjectSaga } from 'utils/injectSaga';
import saga from 'containers/LocationSettingBookingWindow/saga';
import { coreAPI } from 'utils/request';
import { groupBy } from 'lodash';
import usePreLoading from 'hooks/usePreLoading';
import ConfirmModal from './Components/ConfirmModal';
import useSelectLocationManagementId from 'containers/LocationManagement/useSelectLocationManagementId';

const mapDispatchToProps = (dispatch: any) => ({
  doUpdateBookingByYear: (
    locationID: string,
    body: BookingwindowBookingWindowDetail[],
  ) =>
    new Promise((resolve, reject) =>
      dispatch(
        updateLocationBookingSetting({
          data: { locationID, body },
          resolve,
          reject,
        }),
      ),
    ),
});

type GroupDataByMonth = {
  details: {
    marketOcc: number;
    propertyOcc: number;
    id?: string | undefined;
    marketAdr?: number | undefined;
    maxDay?: number | undefined;
    minDay?: number | undefined;
    propertyAdr?: number | undefined;
    serviceMonth?: number | undefined;
    serviceYear?: number | undefined;
  }[];
  month: string;
};

function LocationSettingBookingWindowEdit({
  locationRefCode,
  year,
}: RouteComponentProps<{ locationRefCode: string; year: string }>) {
  useInjectSaga({ key: 'locationSettingBooking', saga });

  const [data, setData] = useState<GroupDataByMonth[]>();
  const [dataLoading, setDataLoading] = useState(false);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const dispatch = useDispatch();
  const { doUpdateBookingByYear } = mapDispatchToProps(dispatch);
  const currentYear = moment().year();
  const selectedYear = Number(year);
  const isPreLoading = usePreLoading();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { locationId } = useSelectLocationManagementId();

  useEffect(() => {
    if (locationRefCode && selectedYear === currentYear) {
      fetchBookingWindow(currentYear);
    }
  }, [locationRefCode]);

  const BookingWindowEditForm = useMemo(
    () => <BookingWindowForm bookingWindows={data || []} onSubmit={onSubmit} />,
    [data],
  );

  if (!locationRefCode || !year || selectedYear !== currentYear) {
    return <Redirect to={ROUTES.HOME} noThrow />;
  }

  async function fetchBookingWindow(year: number) {
    try {
      setDataLoading(true);
      const {
        data,
      }: BookingwindowBookingWindowListResponse = await coreAPI.get(
        `v1/rms/configs/areas/${locationRefCode}/booking-windows?year=${year}`,
      );
      setData(groupDataByMonth(data || []));
    } catch (err) {
      console.log(err);
    } finally {
      setDataLoading(false);
    }
  }

  function groupDataByMonth(
    data: ViewmodelBookingWindow[],
  ): GroupDataByMonth[] {
    if (!data || data.length === 0) return [];
    const convertedData = data.map(item => {
      return {
        ...item,
        marketOcc: item.marketOcc || 0,
        propertyOcc: item.propertyOcc || 0,
      };
    });
    const groupedData = groupBy(convertedData, 'serviceMonth');
    return Object.keys(groupedData).map(key => {
      return {
        details: groupedData[key].sort((d1, d2) => d1.minDay! - d2.minDay!),
        month: moment()
          .month(Number(key) - 1)
          .toISOString(),
      };
    });
  }

  async function onSubmit(value: any) {
    try {
      setIsSubmitting(true);
      const data = value.bkWindows;
      const totalData = data.reduce(
        (acc: BookingwindowBookingWindowDetail[], arr: any) => acc.concat(arr),
        [],
      );
      const submitData = totalData.map((item: any) => {
        return {
          id: item.id,
          marketOcc: item.marketOcc / 100,
          propertyOcc: item.propertyOcc / 100,
        };
      });
      if (locationId) {
        await doUpdateBookingByYear(locationId, submitData);
        showSuccessNotification('Save successfully');
      }
    } catch (err) {
    } finally {
      setIsSubmitting(false);
    }
  }

  function onSave(locationRefCode: string, year: string) {
    navigate(
      formatRoute(
        ROUTES.LOCATIONS_ID_SETTING_BOOKING_WINDOW,
        locationRefCode,
        year,
      ),
    );
    closeConfirmModal();
  }

  function closeConfirmModal() {
    setConfirmModalVisible(false);
  }

  return (
    <>
      <SiderPane.Header
        title={`Edit Booking Window ${year}`}
        backLink={formatRoute(
          ROUTES.LOCATIONS_ID_SETTING_BOOKING_WINDOW,
          locationRefCode,
          year,
        )}
      ></SiderPane.Header>
      <SiderPane.Body>
        {dataLoading ? (
          <SkeletonTable
            numOfRow={5}
            columms={[
              { loaderWidth: '50%', width: '70%' },
              { loaderWidth: '80%', width: '15%' },
              { loaderWidth: '80%', width: '15%' },
            ]}
          />
        ) : (
          BookingWindowEditForm
        )}
      </SiderPane.Body>
      <SiderPane.Footer
        rightExtra={
          <>
            <Button
              loading={isSubmitting}
              disabled={isPreLoading || dataLoading}
              onClick={() => {
                document
                  ?.getElementById('bkwd-form')
                  ?.dispatchEvent(new Event('submit', { cancelable: true }));
              }}
            >
              Save
            </Button>
            <a
              href="/"
              className="px-3 ml-3 text-secondary"
              onClick={e => {
                e.preventDefault();
                setConfirmModalVisible(true);
              }}
            >
              Cancel
            </a>
          </>
        }
      ></SiderPane.Footer>
      <ConfirmModal
        submitText="Yes"
        cancelText="Cancel"
        title="Do you want to Save your changes?"
        description="If you don’t save, your changes will be lost."
        onSave={() => onSave(locationRefCode, year)}
        visible={confirmModalVisible}
        onClose={() => closeConfirmModal()}
      />
    </>
  );
}

export default LocationSettingBookingWindowEdit;
