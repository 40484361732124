import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectIsLogin,
  makeSelectCurrentUser,
  makeSelectCurrentUserIsLoading,
} from 'containers/Auth/selectors';
import { ApplicationRootState } from 'types/app';
import { useInjectSaga } from 'utils/injectSaga';
import authSaga from 'containers/Auth/saga';
import areasSaga from 'containers/LocationManagementHome/saga';
import areasReducer from 'containers/LocationManagementHome/reducer';
import { ViewmodelUser, ViewmodelArea } from 'types/rms-schema';
import { fetchUser } from 'containers/Auth/actions';
import { fetchAreas } from 'containers/LocationManagementHome/actions';
import LocationConfigSaga from 'containers/ConfigurationLocation/saga';
import locationConfigReducer from 'containers/ConfigurationLocation/reducer';
import {
  makeSelectAreasLoading,
  makeSelectAreas,
} from 'containers/LocationManagementHome/selectors';
import { useInjectReducer } from 'utils/injectReducer';

const mapStateToProps = createStructuredSelector<
  ApplicationRootState,
  {
    isLogin: boolean;
    user: ViewmodelUser | null;
    userIsLoading: boolean;
    areasLoading: boolean;
    areas: ViewmodelArea[] | null;
  }
>({
  isLogin: makeSelectIsLogin(),
  user: makeSelectCurrentUser(),
  userIsLoading: makeSelectCurrentUserIsLoading(),
  areasLoading: makeSelectAreasLoading(),
  areas: makeSelectAreas(),
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchCurrentUser: () => dispatch(fetchUser()),
  fetchAreas: () => dispatch(fetchAreas()),
});

export default function useAppIsReady() {
  useInjectSaga({ key: 'auth', saga: authSaga });
  useInjectSaga({ key: 'areas', saga: areasSaga });
  useInjectReducer({ key: 'areas', reducer: areasReducer });
  useInjectSaga({ key: 'locationConfig', saga: LocationConfigSaga });
  useInjectReducer({ key: 'locationConfig', reducer: locationConfigReducer });

  const dispatch = useDispatch();
  const { fetchCurrentUser, fetchAreas } = mapDispatchToProps(dispatch);
  const { isLogin, user, userIsLoading, areas } = useSelector(mapStateToProps);

  useEffect(() => {
    if (!user) {
      fetchCurrentUser();
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!areas) {
      fetchAreas();
    }
  }, []); // eslint-disable-line

  return {
    isLogin,
    failedToFetch: !areas || !user,
    isLoading: userIsLoading || !areas,
  };
}
