import React, { Suspense, useEffect, useMemo } from 'react';
import { RouteComponentProps, Redirect } from '@reach/router';
import ROUTES from 'constants/routes';
import { useInjectSaga } from 'utils/injectSaga';
import { ErrorBoundary } from '@aha/ui';
import trafficSaga from 'containers/ConfigurationLocation/saga';
import trafficReducer from 'containers/ConfigurationLocation/reducer';
import locationManagementReducer from './reducer';
import { useInjectReducer } from 'utils/injectReducer';
import useAppIsReady from 'hooks/useAppIsReady';
import Header from './Header';
import { useDispatch } from 'react-redux';
import { setLocationManagementId } from './actions';
import { formatNestedRoutePath } from '@aha/utils';
import PageTableLoader from 'components/Loaders/PageTableLoader';
import useSelectAreas from 'containers/LocationManagementHome/useSelectAreas';

const LocationManagement: React.FunctionComponent<RouteComponentProps<{
  locationRefCode: string;
}>> = ({ children, location, path }) => {
  useInjectReducer({
    key: 'locationManagement',
    reducer: locationManagementReducer,
  });
  const [locationRefCode, setLocationRefCode] = React.useState(
    location?.pathname.split('/')[2],
  );

  const dispatch = useDispatch();
  useInjectSaga({ key: 'locationConfig', saga: trafficSaga });
  useInjectReducer({ key: 'locationConfig', reducer: trafficReducer });
  const { isLoading, isLogin, failedToFetch } = useAppIsReady();
  const { configuredAreasHavingConfiguredHotel } = useSelectAreas();

  const modifiedChildren = useMemo(
    // @ts-ignore
    () => formatNestedRoutePath(children, path),
    [children],
  );

  useEffect(() => {
    if (!location) {
      return;
    }
    const refCodeFromPath = location.pathname.split('/')[2];
    if (refCodeFromPath) {
      const index = configuredAreasHavingConfiguredHotel.findIndex(
        ({ ref_code }) => ref_code === refCodeFromPath,
      );
      setLocationRefCode(refCodeFromPath);
      index !== -1 &&
        dispatch(
          setLocationManagementId(
            configuredAreasHavingConfiguredHotel[index].id || '',
          ),
        );
    }
  }, [location]);

  if (!isLogin) {
    return <Redirect to={ROUTES.LOGIN} noThrow />;
  }

  if (isLoading) {
    return <PageTableLoader />;
  }

  if (failedToFetch) {
    return null;
  }

  // if (configuredAreasHavingConfiguredHotel.length === 0) {
  //   return <Redirect to={ROUTES.LOCATIONS} noThrow />;
  // }

  // if (
  //   locationRefCode !== undefined &&
  //   configuredAreasHavingConfiguredHotel.findIndex(
  //     ({ ref_code }) => ref_code === locationRefCode,
  //   ) === -1
  // ) {
  //   return <Redirect to={ROUTES.LOCATIONS} noThrow />;
  // }

  return (
    <ErrorBoundary>
      <Header
        locationRefCode={locationRefCode}
        configuredAreas={configuredAreasHavingConfiguredHotel}
      />
      <Suspense fallback={<PageTableLoader />}>{modifiedChildren}</Suspense>
    </ErrorBoundary>
  );
};

export default LocationManagement;
