import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types/app';
import { initialState } from './reducer';

const selectAuthState = (state: ApplicationRootState) => {
  return state.auth || initialState;
};

const makeSelectIsLogin = () =>
  createSelector(selectAuthState, authState => authState.isLogin);

const makeSelectLoginError = () =>
  createSelector(selectAuthState, authState => authState.error);

const makeSelectCurrentUser = () =>
  createSelector(selectAuthState, authState => authState.user);

const makeSelectCurrentUserIsLoading = () =>
  createSelector(selectAuthState, authState => authState.userIsLoading);

export {
  makeSelectIsLogin,
  makeSelectLoginError,
  makeSelectCurrentUser,
  makeSelectCurrentUserIsLoading,
};
