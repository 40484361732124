import React from 'react';
import { Skeleton } from '@uxui/skeleton-loader';
import { SkeletonTable } from '@aha/ui';

const SettingPaneLoader = () => {
  return (
    <div>
      <Skeleton width="80px" height="16px" className="mb-6" />
      <div className="ml-2 mt-2">
        <SkeletonTable />
      </div>
    </div>
  );
};

export default SettingPaneLoader;
