import { call, put, takeLatest } from 'redux-saga/effects';
import { coreAPI } from 'utils/request';
import ActionTypes from './constants';

import { HotelHotelResponse } from 'types/rms-schema';
import { showErrorNotification } from '@aha/utils';
import {
  fetchDashboardHotelFailure,
  fetchDashboardHotelSuccess,
} from './actions';
import { ExtractAction } from 'types/actions';
import { DashboardAction } from './types';

export function* fetchDashboardHotelInfo(
  action: ExtractAction<
    DashboardAction,
    ActionTypes.FETCH_DASHBOARD_HOTEL_INFO
  >,
) {
  const { payload } = action;
  try {
    const { hotel }: HotelHotelResponse = yield call(
      coreAPI.get,
      `v1/rms/hotels/${payload}`,
    );
    yield put(fetchDashboardHotelSuccess(hotel!));
  } catch (err) {
    yield put(fetchDashboardHotelFailure(err));
    showErrorNotification('Fail to fetch', err);
  }
}

export default function* watchFetchHotelInfo() {
  yield takeLatest(
    ActionTypes.FETCH_DASHBOARD_HOTEL_INFO,
    fetchDashboardHotelInfo,
  );
}
