import { call, put, takeLatest, all, takeEvery } from 'redux-saga/effects';
import { coreAPI } from 'utils/request';
import ActionTypes from './constants';
import {
  fetchAreaHotelsSuccess,
  fetchAreaHotelsFailure,
  fetchAreasSuccess,
  fetchAreasFailure,
  createAreaSuccess,
  deleteAreaSuccess,
  createAreaConfigSuccess,
  assignHotelsForAreaSuccess,
} from './actions';
import { ExtractAction } from 'types/actions';
import { AreasActions } from './types';
import { showErrorNotification } from '@aha/utils';

export function* fetchAreas() {
  try {
    const { data } = yield call(coreAPI.get, '/v1/rms/configs/areas');

    yield put(fetchAreasSuccess(data));
  } catch (err) {
    yield put(fetchAreasFailure(err));
  }
}

export function* watchFetchAreas() {
  yield takeLatest(ActionTypes.FETCH_AREAS, fetchAreas);
}

export function* createArea(
  action: ExtractAction<AreasActions, ActionTypes.CREATE_AREA>,
) {
  const {
    payload: { data: name, resolve, reject },
  } = action;

  try {
    const { data } = yield call(coreAPI.post, '/v1/rms/configs/areas', {
      name,
    });

    yield put(createAreaSuccess(data));
    resolve();
  } catch (err) {
    showErrorNotification('Fail to create an area!');
    reject();
  }
}

export function* watchCreateArea() {
  yield takeEvery(ActionTypes.CREATE_AREA, createArea);
}

export function* deleteArea(
  action: ExtractAction<AreasActions, ActionTypes.DELETE_AREA>,
) {
  try {
    const { payload: areaID } = action;
    yield call(coreAPI.delete, `/v1/rms/configs/areas/${areaID}`);
    yield put(deleteAreaSuccess(areaID));
  } catch (err) {
    showErrorNotification('Fail to delete an area!');
  }
}

export function* watchDeleteArea() {
  yield takeEvery(ActionTypes.DELETE_AREA, deleteArea);
}

export function* createAreaConfig(
  action: ExtractAction<AreasActions, ActionTypes.CREATE_AREA_CONFIG>,
) {
  try {
    const { payload } = action;
    const { data } = yield call(
      coreAPI.post,
      `/v1/rms/configs/areas/${payload.areaID}`,
      payload,
    );

    yield put(createAreaConfigSuccess(data));
  } catch (err) {
    showErrorNotification('Fail to create configuration for an area!');
  }
}

export function* watchCreateAreaConfig() {
  yield takeEvery(ActionTypes.CREATE_AREA_CONFIG, createAreaConfig);
}

export function* fetchAreaHotels(
  action: ExtractAction<AreasActions, ActionTypes.FETCH_AREA_HOTELS>,
) {
  try {
    const { payload: areaID } = action;

    const { data } = yield call(
      coreAPI.get,
      `/v1/rms/configs/areas/${areaID}/hotels`,
    );

    yield put(fetchAreaHotelsSuccess(data));
  } catch (err) {
    yield put(fetchAreaHotelsFailure(err));
  }
}

export function* watchFetchAreaHotels() {
  yield takeLatest(ActionTypes.FETCH_AREA_HOTELS, fetchAreaHotels);
}

export function* assignHotelsForArea(
  action: ExtractAction<AreasActions, ActionTypes.ASSIGN_HOTELS_FOR_AREA>,
) {
  const {
    payload: { resolve, reject, data: body },
  } = action;

  try {
    const { data } = yield call(
      coreAPI.post,
      `/v1/rms/configs/areas/${body.AreaID}/hotels`,
      body,
    );

    yield put(assignHotelsForAreaSuccess(body.AreaID || '', body));
    resolve();
  } catch (err) {
    showErrorNotification(`Fail to assign hotels for an area #${body.AreaID!}`);
    reject();
  }
}

export function* watchAssignHotelsForArea() {
  yield takeEvery(ActionTypes.ASSIGN_HOTELS_FOR_AREA, assignHotelsForArea);
}

export default function* watchAll() {
  yield all([
    watchFetchAreas(),
    watchCreateArea(),
    watchDeleteArea(),
    watchCreateAreaConfig(),
    watchFetchAreaHotels(),
    watchAssignHotelsForArea(),
  ]);
}
