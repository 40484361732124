enum ActionTypes {
  FETCH_AREAS = 'areas/FETCH_AREAS',
  FETCH_AREAS_SUCCESS = 'areas/FETCH_AREAS_SUCCESS',
  FETCH_AREAS_FAILURE = 'areas/FETCH_AREAS_FAILURE',

  CREATE_AREA = 'areas/CREATE_AREA',
  CREATE_AREA_SUCCESS = 'areas/CREATE_AREA_SUCCESS',
  CREATE_AREA_FAILURE = 'areas/CREATE_AREA_FAILURE',

  DELETE_AREA = 'areas/DELETE_AREA',
  DELETE_AREA_SUCCESS = 'areas/DELETE_AREA_SUCCESS',
  DELETE_AREA_FAILURE = 'areas/DELETE_AREA_FAILURE',

  CREATE_AREA_CONFIG = 'areas/CREATE_AREA_CONFIG',
  CREATE_AREA_CONFIG_SUCCESS = 'areas/CREATE_AREA_CONFIG_SUCCESS',
  CREATE_AREA_CONFIG_FAILURE = 'areas/CREATE_AREA_CONFIG_FAILURE',

  FETCH_AREA_HOTELS = 'areas/FETCH_AREA_HOTELS',
  FETCH_AREA_HOTELS_SUCCESS = 'areas/FETCH_AREA_HOTELS_SUCCESS',
  FETCH_AREA_HOTELS_FAILURE = 'areas/FETCH_AREA_HOTELS',

  ASSIGN_HOTELS_FOR_AREA = 'areas/ASSIGN_HOTELS_FOR_AREA',
  ASSIGN_HOTELS_FOR_AREA_SUCCESS = 'areas/ASSIGN_HOTELS_FOR_AREA_SUCCESS',
  ASSIGN_HOTELS_FOR_AREA_FAILURE = 'areas/ASSIGN_HOTELS_FOR_AREA',

  SET_AREA_IS_CONFIGURED = 'areas/SET_AREA_IS_CONFIGURED',
  SET_AREA_TO_HAVE_HOTEL_CONFIGURED = 'areas/SET_AREA_TO_HAVE_HOTEL_CONFIGURED',
}

export default ActionTypes;
