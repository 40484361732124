import React, { useMemo } from 'react';

import { Tabs, FormInputNumber, FormAutoSave, TextBlock } from '@aha/ui';
import 'antd/lib/table/style/index';
import { ColumnProps } from 'antd/lib/table';
import { AreaBookingWindowDetail, AreaBookingWindow } from 'types/rms-schema';
import styled from 'styled-components';
import moment from 'moment';
import tw from 'tailwind.macro';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import dateRangeData from './dateRange';

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    ${tw`w-full`}
    > div:first-child {
      ${tw`w-full flex justify-around`}
    }
  }
  .ant-tabs-nav .ant-tabs-tab > span {
    ${tw`text-grey-darker`}
  }
`;

const StyledTable = styled.table`
  ${tw`w-full`}
  table-layout: fixed;

  .ant-table-thead > tr {
    > th {
      ${tw`whitespace-no-wrap text-grey-darker h-14 font-medium`};
      font-size: 11px;
      text-transform: unset;
      line-height: unset;
    }
    > td {
      ${tw`whitespace-no-wrap text-secondary py-0 h-14`};
    }
  }

  .ant-table-tbody > tr {
    td {
      height: 4rem !important;
    }

    &:first-child > td {
      height: 40px !important;
      padding: 0px;
      overflow: hidden;
    }
  }
`;

export interface ColumnHeaderProps {
  title?: string | React.ReactNode;
  subTitle?: string | React.ReactNode;
}

const ColHeader = styled.div`
  ${tw`flex flex-col justify-center -my-2`}
`;

const ColumnHeader = ({ title, subTitle }: ColumnHeaderProps) => {
  return (
    <ColHeader>
      <div className="uppercase text-2xs font-medium">{title}</div>
      <div className="text-3xs font-medium">{subTitle}</div>
    </ColHeader>
  );
};

const Container = styled.form`
  border: 1px solid var(--grey-light);

  .ant-form-explain {
    position: absolute;
    font-weight: 500;
    font-size: 10px !important;
    bottom: -12px;
    min-height: auto !important;
  }
`;

type BookingFormDetailsItem = Required<
  Omit<AreaBookingWindowDetail, 'minDay' | 'maxDay'>
> & {
  days: [number, number];
};

export interface BookingFormValues {
  bkWindows: BookingFormDetailsItem[][];
}

interface BookingFormProps {
  bookingWindows: AreaBookingWindow[];
  onSubmit: (values: BookingFormValues) => void;
  onSave?: (value: BookingFormValues) => void;
}

const TableRow = React.memo(
  ({
    name,
    remove,
    index,
    dataIndexs,
  }: {
    name: string;
    index: number;
    dataIndexs: string[];
    remove: (idx: number) => void;
  }) => {
    const rowData: { [k: string]: React.ReactNode } = {
      days: (
        <div className="flex items-center">
          <Field
            name={`${name}.days`}
            render={({ input: { value } }) => {
              const curDateRange = dateRangeData.find(
                d => d.days[0] === value[0],
              );

              return (
                <TextBlock
                  title={`${
                    value[0] === 90
                      ? `${value[0]}+ `
                      : `${value[0]} - ${value[1]}`
                  } ${value[1] === 1 ? 'day' : 'days'} `}
                  subTitle={
                    <span className="text-sm lowercase text-secondary font-normal">
                      {curDateRange?.description}
                    </span>
                  }
                ></TextBlock>
              );
            }}
          />
        </div>
      ),
      propertyOcc: (
        <FormInputNumber
          name={`${name}.propertyOcc`}
          className="w-36"
          min={0}
          max={100}
          step={1}
          precision={2}
          formatter={(value: number | string | undefined = 0) =>
            `${value > 100 ? 0 : value}%`
          }
          parser={(value: string = '') => value.replace('%', '')}
        />
      ),
      marketOcc: (
        <FormInputNumber
          name={`${name}.marketOcc`}
          className="w-36"
          min={0}
          max={100}
          step={1}
          precision={2}
          formatter={(value: number | string | undefined = 0) =>
            `${value > 100 ? 0 : value}%`
          }
          parser={(value: string = '') => value.replace('%', '')}
        />
      ),
    };

    return (
      <tr key={index} className="ant-table-row ant-table-row-level-0">
        {dataIndexs.map((dataIndex: string, idx: number) => {
          return <td key={idx}>{rowData[dataIndex]}</td>;
        })}
      </tr>
    );
  },
);

const BookingWindowsTable = React.memo(
  ({
    columns,
    index,
    dataIndexs,
  }: {
    columns: ColumnProps<AreaBookingWindowDetail>[];
    index: number;
    dataIndexs: string[];
  }) => {
    return (
      <StyledTable>
        <colgroup>
          {columns.map((column, idx: number) => (
            <col
              key={idx}
              style={{ width: column?.width, minWidth: column?.width }}
            ></col>
          ))}
        </colgroup>
        <thead className="ant-table-thead">
          <tr>
            {columns.map((column, idx: number) => (
              <th key={idx}>
                <span className="ant-table-header-column">
                  <span className="ant-table-header-column">
                    {column.title}
                  </span>
                </span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="ant-table-tbody">
          <FieldArray name={`bkWindows[${index}]`}>
            {({ fields }) => (
              <>
                <tr className="ant-table-row ant-table-row-level-0"></tr>
                {fields.map((name: string, index: number) => (
                  <TableRow
                    key={index}
                    name={name}
                    index={index}
                    remove={fields.remove}
                    dataIndexs={dataIndexs}
                  />
                ))}
              </>
            )}
          </FieldArray>
        </tbody>
      </StyledTable>
    );
  },
);

interface TableProps {
  columns: ColumnProps<AreaBookingWindowDetail>[];
  dataIndexs: string[];
}

function convertStoredValueToBkWindowsForm(
  bookingWindows: AreaBookingWindow[],
): BookingFormValues {
  let bkWindows;

  if (bookingWindows.length === 0) {
    bkWindows = new Array(12).fill(0).map((_, idx: number) =>
      dateRangeData.map(date => ({
        days: date.days,
        propertyOcc: 0,
        marketOcc: 0,
      })),
    ) as BookingFormDetailsItem[][];
  } else {
    bkWindows = bookingWindows.map(({ details }: AreaBookingWindow) => {
      return details?.map(
        ({
          minDay = 0,
          maxDay = 1,
          propertyOcc = 0,
          marketOcc = 0,
          ...rest
        }: AreaBookingWindowDetail) =>
          ({
            ...rest,
            propertyOcc: +propertyOcc * 100,
            marketOcc: +marketOcc * 100,
            days: [minDay, maxDay],
          } || []),
      );
    }) as BookingFormDetailsItem[][];
  }

  return {
    bkWindows,
  };
}

export default function CreateBookingWindow({
  bookingWindows,
  onSubmit,
  onSave,
}: BookingFormProps) {
  const initialValues = convertStoredValueToBkWindowsForm(bookingWindows);

  const { columns, dataIndexs }: TableProps = useMemo(() => {
    const columns = [
      {
        title: <span className="uppercase">Date range</span>,
        dataIndex: 'days',
        width: '70%',
      },
      {
        title: (
          <ColumnHeader
            title="Reservation"
            subTitle="My property"
          ></ColumnHeader>
        ),
        dataIndex: 'propertyOcc',
        width: 110,
      },
      {
        title: (
          <ColumnHeader title="Reservation" subTitle="Market"></ColumnHeader>
        ),
        dataIndex: 'marketOcc',
      },
    ];

    const dataIndexs: string[] = columns.map(col => col.dataIndex as string);

    return { columns, dataIndexs };
  }, []);

  const monthTabPanes: JSX.Element[] = useMemo(
    () =>
      moment.monthsShort().map((month: string, mIndex: number) => {
        return (
          <Tabs.TabPane tab={<span>{month}</span>} key={month}>
            <BookingWindowsTable
              columns={columns}
              dataIndexs={dataIndexs}
              index={mIndex}
            />
          </Tabs.TabPane>
        );
      }),
    [columns, dataIndexs],
  );

  return (
    <Form<BookingFormValues>
      onSubmit={onSubmit}
      subscription={{ submitting: true, pristine: true }}
      mutators={{
        ...arrayMutators,
      }}
      initialValues={initialValues}
      render={({ handleSubmit }) => (
        <Container id="bkwd-form" onSubmit={handleSubmit}>
          <FormAutoSave<BookingFormValues>
            callback={values => onSave && onSave(values)}
          />
          <StyledTabs className="w-full">{monthTabPanes}</StyledTabs>
        </Container>
      )}
    />
  );
}
