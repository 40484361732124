import { ApplicationRootState } from 'types/app';

const reducers: (keyof ApplicationRootState)[] = [
  'auth',
  'areas',
  'hotelConfig',
  'hotels',
  'locations',
  'rateManager',
  'locationConfig',
];

type T = { [k in keyof ApplicationRootState]: (state: object) => object };

export const mockReducers = reducers.reduce(
  (a: T, c) => ({ ...a, [c]: (state = {}) => state }),
  {} as T,
);
