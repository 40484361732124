import React from 'react';
import HeaderLoader from './HeaderLoader';
import PageTableLoader from './PageTableLoader';

const AppLoader = () => {
  return (
    <div className="h-screen pt-14 pb-20">
      <div className="absolute pin-x pin-t">
        <HeaderLoader />
      </div>
      <PageTableLoader />
    </div>
  );
};

export default AppLoader;
