import React from 'react';
import { Skeleton } from '@uxui/skeleton-loader';
import { SkeletonTable } from '@aha/ui';

const PageTableLoader = () => {
  return (
    <>
      <div className="flex items-center justify-between px-8 py-5">
        <div>
          <Skeleton
            width="200px"
            height="19px"
            baseColor="#ddd"
            highlightColor="#eee"
          />
          <Skeleton
            className="ml-4"
            width="100px"
            height="19px"
            baseColor="#ddd"
            highlightColor="#eee"
          />
        </div>
        <div>
          <Skeleton
            className="ml-4"
            width="100px"
            height="14px"
            baseColor="#ddd"
            highlightColor="#eee"
          />
        </div>
      </div>
      <div className="bg-white px-6">
        <SkeletonTable />
      </div>
    </>
  );
};

export default PageTableLoader;
