import { DashboardAction, DashboardState } from './types';
import ActionTypes from './constants';
import produce from 'immer';

export const initialState: DashboardState = {
  hotelId: null,
  hotel: null,
  error: null,
  loading: false,
};

const dashboardReducer = (
  state: DashboardState = initialState,
  action: DashboardAction,
): DashboardState =>
  produce(state, draft => {
    switch (action.type) {
      case ActionTypes.SET_DASHBOARD_HOTEL_ID:
        draft.hotelId = action.payload;
        break;
      case ActionTypes.FETCH_DASHBOARD_HOTEL_INFO:
        draft.loading = true;
        draft.error = null;
        break;
      case ActionTypes.FETCH_DASHBOARD_HOTEL_INFO_SUCCESS:
        draft.loading = false;
        draft.hotel = action.payload;
        if (action.payload && action.payload.id) {
          draft.hotelId = action.payload.id;
        }
        break;
      case ActionTypes.FETCH_DASHBOARD_HOTEL_INFO_FAILURE:
        draft.loading = false;
        draft.error = action.payload;
        break;
    }
  });

export default dashboardReducer;
