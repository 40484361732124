export interface DateRangeProps {
  days: [number, number];
  description: string;
}

export const MAX_BK_WINDOW_DAY = 999999;

const dateRangeData: DateRangeProps[] = [
  {
    days: [0, 1],
    description: '(last - second)',
  },
  {
    days: [2, 3],
    description: '(last - minute)',
  },
  {
    days: [4, 5],
    description: '(about 1 week)',
  },
  {
    days: [8, 14],
    description: '(about 2 weeks)',
  },
  {
    days: [15, 30],
    description: '(about 1 month)',
  },
  {
    days: [31, 60],
    description: '(about 2 months)',
  },
  {
    days: [61, 90],
    description: '(about 3 months)',
  },
  {
    days: [90, MAX_BK_WINDOW_DAY],
    description: '(more than 3 months)',
  },
];

export default dateRangeData;
