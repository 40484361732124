import React from 'react';
import GlobalHeader from 'components/GlobalHeader';
import { Location, Link } from '@reach/router';
import NavItem from 'components/NavItem';
import { CountBadge, Icon } from '@aha/ui';
import ROUTES from 'constants/routes';
import { ViewmodelArea } from 'types/rms-schema';
import { formatRoute } from '@aha/utils';
import classnames from 'classnames';

interface HeaderProps {
  locationRefCode: string | undefined;
  configuredAreas: ViewmodelArea[];
}

export default function Header({
  locationRefCode,
  configuredAreas,
}: HeaderProps) {
  const currentLocation =
    configuredAreas.find(({ ref_code }) => locationRefCode === ref_code) || {};
  const count = currentLocation ? currentLocation.totalHotelNotConfigured : 0;
  return (
    <Location>
      {({ location, navigate }) => {
        let leftRender: React.ReactNode = null;
        if (location.pathname !== ROUTES.LOCATIONS && locationRefCode) {
          leftRender = (
            <>
              <NavItem
                active={
                  location.pathname ===
                  formatRoute(ROUTES.LOCATIONS_ID, locationRefCode)
                }
                onClick={() =>
                  navigate(formatRoute(ROUTES.LOCATIONS_ID, locationRefCode))
                }
              >
                Hotel Management
              </NavItem>
              <NavItem
                active={location.pathname.includes(
                  formatRoute(ROUTES.LOCATIONS_ID_SETTING, locationRefCode),
                )}
                onClick={() =>
                  navigate(
                    formatRoute(
                      ROUTES.LOCATIONS_ID_SETTING_TRAFFIC,
                      locationRefCode,
                    ),
                  )
                }
                className="pr-9"
              >
                <CountBadge
                  count={count}
                  custom={{
                    bgColor: 'var(--green-lighter)',
                    textColor: 'var(--primary)',
                  }}
                >
                  System Setup
                </CountBadge>
              </NavItem>
            </>
          );
        }

        const rightRender = (
          <Link
            to={ROUTES.LOCATIONS}
            className={classnames('flex items-center mr-2 hover:text-primary', {
              'pointer-events-none opacity-0':
                ROUTES.LOCATIONS === location.pathname,
            })}
          >
            <Icon type="list-ul" className="mr-2" />
            Manage Location
          </Link>
        );

        return (
          <GlobalHeader
            logoLink={ROUTES.LOCATIONS}
            leftRender={leftRender}
            rightRender={rightRender}
          />
        );
      }}
    </Location>
  );
}
