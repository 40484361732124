import { TrafficConfigFormState } from './types';
import { ApplyConfigLocationParams } from './actions';

export const LOCATION_CONFIG_SAVE_KEY = 'ahaRmsConfigLocation';

export interface LocationConfigSaveObject {
  currentStep: string;
  form: TrafficConfigFormState;
  userId: string;
}

export function saveLocationConfigToLocalStorage(
  config: LocationConfigSaveObject,
) {
  localStorage.setItem(LOCATION_CONFIG_SAVE_KEY, JSON.stringify(config));
}

export function getLocationConfigFromLocalStorage(
  locationId: string,
  userId: string,
) {
  const stringifiedConfig = localStorage.getItem(LOCATION_CONFIG_SAVE_KEY);
  if (stringifiedConfig) {
    try {
      const config: LocationConfigSaveObject = JSON.parse(stringifiedConfig);
      if (
        config?.userId &&
        config.userId === userId &&
        config?.form?.areaID &&
        config.form.areaID === locationId
      ) {
        delete config.userId;
        return config as ApplyConfigLocationParams;
      }
    } catch (error) {}
  }

  clearLocationConfigFromLocalStorage();
  return null;
}

export function clearLocationConfigFromLocalStorage() {
  localStorage.removeItem(LOCATION_CONFIG_SAVE_KEY);
}
