/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import authReducer from 'containers/Auth/reducer';
import actionTypes from 'containers/Auth/constants';
import { mockReducers } from 'testUtils/mockReducers';

// we use useReducer to dynamically inject a reducer in a container
// however, it will give a warning of undefined reducers when doing test
// this code aims to solve the problem by mocking all the reducers in a test env
const initialInjectedReducers =
  process.env.NODE_ENV === 'test' ? mockReducers : {};

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(
  injectedReducers = initialInjectedReducers,
) {
  const appReducer = combineReducers({
    auth: authReducer,
    ...injectedReducers,
  });

  // FIXME:
  // @ts-ignore
  const rootReducer = (state, action) => {
    if (action.type === actionTypes.LOG_OUT) {
      state = undefined;
    }

    return appReducer(state, action);
  };

  return rootReducer;
}
