import React, { Suspense, useMemo } from 'react';
import ROUTES from 'constants/routes';
import { RouteComponentProps, navigate, Redirect } from '@reach/router';
import { SiderMenu } from '@aha/ui';
import { NavItemProps } from '@aha/ui/src/components/SiderMenu';
import { formatNestedRoutePath } from '@aha/utils';
import SettingPaneLoader from 'components/Loaders/SettingPaneLoader';
import SiderPane from 'components/SiderPane';

const navItems: NavItemProps[] = [
  {
    id: 'sidebar.menu.traffic',
    name: 'Traffic',
    link: ROUTES.LOCATIONS_ID_SETTING_TRAFFIC,
  },
  {
    id: 'sidebar.menu.bookingWindow',
    name: 'Booking Window',
    link: ROUTES.LOCATIONS_ID_SETTING_BOOKING_WINDOW,
  },
  {
    id: 'sidebar.menu.events',
    name: 'Events',
    link: ROUTES.LOCATIONS_ID_SETTING_EVENTS,
  },
];

const LocationSettingDetailLayout: React.FC<RouteComponentProps<{
  locationRefCode: string;
}>> = ({ children, location, locationRefCode }) => {
  const { pathname } = location!;

  const modifiedChildren = useMemo(
    // @ts-ignore
    () => formatNestedRoutePath(children, ROUTES.LOCATIONS_ID_SETTING),
    [children],
  );

  if (!locationRefCode) {
    return <Redirect to={ROUTES.LOCATIONS} noThrow />;
  }

  const mappedItems = navItems.map(item => ({
    ...item,
    link: item.link.replace(':locationRefCode', locationRefCode),
  }));

  const selectedKey = (
    mappedItems.filter(({ link }) => pathname.startsWith(link))[0] || {}
  ).link;

  return (
    <div className="p-2">
      <SiderMenu
        pathname={pathname}
        navItems={mappedItems}
        onItemClick={item => navigate(item.link)}
        selectedKey={selectedKey}
        defaultSelectedKeys={[pathname]}
      >
        <SiderPane>
          <Suspense fallback={<SettingPaneLoader />}>
            {modifiedChildren}
          </Suspense>
        </SiderPane>
      </SiderMenu>
    </div>
  );
};

export default LocationSettingDetailLayout;
