import React, { useMemo } from 'react';
import { Dropdown, Menu } from 'antd';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import { Link } from '@reach/router';
import ROUTES from 'constants/routes';
import { CountBadge } from '@aha/ui';
import classnames from 'classnames';
import { formatRoute } from '@aha/utils';

const MenuStyled = styled(Menu)`
  position: relative;
  width: 230px;
  box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.15);
  &:before {
    ${tw`absolute`};
    content: '';
    top: -5px;
    right: 10px;
    box-shadow: -2px -2px 0px 0px rgba(0, 0, 0, 0.025);
    border-right: solid 5px var(--grey-lightest);
    border-bottom: solid 5px var(--grey-lightest);
    border-top: solid 5px var(--white);
    border-left: solid 5px var(--white);
    transform: rotate(45deg);
  }
  .ant-dropdown-menu-item {
    padding: 5px 24px;
  }
  .ant-dropdown-menu {
    padding: 0;
  }
  .ant-badge-count {
    box-shadow: none;
  }
`;

const CaretDown = styled.span`
  border-right: solid 3px var(--black);
  border-bottom: solid 3px var(--black);
  border-top: solid 3px transparent;
  border-left: solid 3px transparent;
  border-radius: 1px 2px;
  transform: rotate(45deg);
`;

export interface InfoDropdownProps {
  hotelName: string;
  location: string;
  locationRefCode: string;
  className?: string;
}

const InfoDropdown = ({
  hotelName = '',
  location = '',
  locationRefCode = '',
  className = '',
}: InfoDropdownProps) => {
  const listMenu = useMemo(
    () => (
      <MenuStyled className="mt-2 pb-3 pt-0">
        <div className="content-center py-4 px-6 bg-grey-lightest hover:bg-grey-lightest">
          <div className="mb-1 font-medium">{hotelName}</div>
          <div className="text-grey-darker">Location: {location}</div>
        </div>
        <Menu.Divider className="mt-0" />
        <Menu.Item className="hover:grey-lighter py-1 " key="1">
          <Link to={formatRoute(ROUTES.LOCATIONS_ID, locationRefCode)}>
            <span>Hotel Management</span>
          </Link>
        </Menu.Item>
        <Menu.Item
          className="hover:grey-lighter py-1 flex items-center justify-between"
          key="2"
        >
          <Link
            to={formatRoute(
              ROUTES.LOCATIONS_ID_SETTING_TRAFFIC,
              locationRefCode,
            )}
          >
            <span>System Setup</span>
          </Link>
          <CountBadge
            count={24}
            offsetY={0}
            className="pr-4"
            custom={{
              textColor: 'var(--primary)',
              bgColor: 'var(--green-lighter)',
            }}
          />
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item className="py-1 hover:grey-lighter" key="3">
          <Link to={ROUTES.LOCATIONS}>
            <span>Location List</span>
          </Link>
        </Menu.Item>
      </MenuStyled>
    ),
    [hotelName, location],
  );
  return (
    <Dropdown overlay={listMenu} placement="bottomRight">
      <div
        className={classnames(
          'flex items-center justify-between cursor-pointer',
          className,
        )}
      >
        <span className="truncate">{hotelName}</span>
        <div className="flex items-center justify-center w-6 h-6 flex-none">
          <CaretDown />
        </div>
      </div>
    </Dropdown>
  );
};

export default InfoDropdown;
