import React from 'react';
import GlobalHeader from 'components/GlobalHeader';
import { Location } from '@reach/router';
import InfoDropdown from './components/InfoDropdown';
import NavItem from 'components/NavItem';
import { formatRoute } from '@aha/utils';
import ROUTES from 'constants/routes';
import useSelectAreas from 'containers/LocationManagementHome/useSelectAreas';
import { ViewmodelHotel, ViewmodelArea } from 'types/rms-schema';
import { Skeleton } from '@uxui/skeleton-loader';
import {
  makeSelectDashboardHotel,
  makeSelectDashboardHotelLoading,
} from './selectors';
import { createStructuredSelector } from 'reselect';
import { ApplicationRootState } from 'types/app';
import { useSelector } from 'react-redux';

const mapStateToProps = createStructuredSelector<
  ApplicationRootState,
  {
    hotel: ViewmodelHotel | null;
    hotelLoading: boolean;
  }
>({
  hotel: makeSelectDashboardHotel(),
  hotelLoading: makeSelectDashboardHotelLoading(),
});

export default function Header() {
  const { areas } = useSelectAreas();
  const { hotel, hotelLoading } = useSelector(mapStateToProps);

  const hotelArea: ViewmodelArea | undefined = (areas || []).find(
    area => area.id === hotel?.areaID,
  );

  return (
    <Location>
      {({ location, navigate }) => {
        const { pathname } = location;
        return (
          <GlobalHeader
            leftRender={
              <div className="flex items-center">
                {!hotelLoading ? (
                  <InfoDropdown
                    hotelName={hotel?.name || ''}
                    location={hotelArea?.name || ''}
                    locationRefCode={hotelArea?.ref_code || ''}
                    className="w-40"
                  />
                ) : (
                  <div className="w-40 pr-3">
                    <Skeleton width="120px" height="14px" />
                  </div>
                )}
                <NavItem
                  active={
                    pathname ===
                    ROUTES.HOTELS_ID.replace(
                      ':hotelRefCode',
                      hotel?.refCode || '',
                    )
                  }
                  onClick={() =>
                    navigate(
                      formatRoute(ROUTES.HOTELS_ID, hotel?.refCode || ''),
                    )
                  }
                >
                  Dashboard
                </NavItem>
                <NavItem
                  onClick={() =>
                    navigate(
                      formatRoute(
                        ROUTES.HOTELS_ID_SETTING_BUDGET,
                        hotel?.refCode || '',
                      ),
                    )
                  }
                  active={pathname.includes('setting')}
                >
                  Settings
                </NavItem>
              </div>
            }
          />
        );
      }}
    </Location>
  );
}
