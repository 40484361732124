import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { coreAPI } from 'utils/request';
import ActionTypes from './constants';
import {
  createTrafficConfigSuccess,
  createTrafficConfigFailure,
  setTrafficConfigSubmitting,
  submitLocationTrafficConfigSuccess,
  applyPersistConfigLocation,
  requestPersistConfigFail,
} from './actions';
import { TrafficConfigActions, TrafficConfigFormState } from './types';
import { ExtractAction } from 'types/actions';
import { ApplicationRootState } from 'types/app';
import { showErrorNotification } from '@aha/utils';
import { AreaConfigRequest } from 'types/rms-schema';
import {
  saveLocationConfigToLocalStorage,
  getLocationConfigFromLocalStorage,
} from './helpers';
import { setAreaIsConfigured } from 'containers/LocationManagementHome/actions';

export function* doCreateTrafficConfig(
  action: ExtractAction<
    TrafficConfigActions,
    ActionTypes.CREATE_TRAFFIC_CONFIG
  >,
) {
  try {
    const {
      payload: { areaID, ...body },
    } = action;

    const { data } = yield call(
      coreAPI.post,
      `/v1/rms/configs/areas/${areaID}/configuration`,
      body,
    );
    yield put(createTrafficConfigSuccess(data));
  } catch (err) {
    yield put(createTrafficConfigFailure(err));
  }
}

export const selectConfigLocationFormState = (state: ApplicationRootState) => ({
  currentStep: state.locationConfig.currentStep,
  form: state.locationConfig.form,
  userId: state.auth.user ? state.auth.user.id : '',
});

export function* doLocallySaveConfigLocation(
  action: ExtractAction<
    TrafficConfigActions,
    ActionTypes.SAVE_CONFIG_LOCATION_TO_LOCAL
  >,
) {
  const { currentStep, form, userId } = yield select(
    selectConfigLocationFormState,
  );

  saveLocationConfigToLocalStorage({
    currentStep,
    form: { ...form, ...action.payload },
    userId,
  });
}

export function* watchCreateTrafficConfig() {
  yield takeLatest(ActionTypes.CREATE_TRAFFIC_CONFIG, doCreateTrafficConfig);
}

export const selectLocationConfigData = (state: ApplicationRootState) => {
  const form = state.locationConfig.form;
  return { ...form } as TrafficConfigFormState;
};

export function* watchLocallySaveConfigLocation() {
  yield takeLatest(
    ActionTypes.SAVE_CONFIG_LOCATION_TO_LOCAL,
    doLocallySaveConfigLocation,
  );
}

export const selectConfigLocationCred = (state: ApplicationRootState) => ({
  locationId: state.locationConfig.locationId,
  userId: state.auth.user ? state.auth.user.id : '',
});

export function* requestLocationConfig() {
  const { locationId, userId } = yield select(selectConfigLocationCred);
  const config = getLocationConfigFromLocalStorage(locationId, userId);
  if (config) {
    yield put(applyPersistConfigLocation(config));
  } else {
    yield put(requestPersistConfigFail());
  }
}

export function* watchRequestLocationConfig() {
  yield takeLatest(
    ActionTypes.REQUEST_PERSIST_CONFIG_LOCATION,
    requestLocationConfig,
  );
}

export function* doSubmitLocationConfig(
  action: ExtractAction<
    TrafficConfigActions,
    ActionTypes.SUBMIT_LOCATION_CONFIG
  >,
) {
  const form: TrafficConfigFormState = yield select(selectLocationConfigData);
  const {
    payload: { resolve, reject },
  } = action;

  const body: AreaConfigRequest = {
    ...form,
  };

  try {
    yield call(
      coreAPI.post,
      `/v1/rms/configs/areas/${form.areaID}/configuration`,
      body,
    );
    yield put(submitLocationTrafficConfigSuccess());
    yield put(setAreaIsConfigured(form.areaID));
    resolve('success');
  } catch (e) {
    yield put(setTrafficConfigSubmitting(false));
    showErrorNotification('Fail to submit', e);
    reject(e);
  }
}

export function* watchSubmitLocationConfig() {
  yield takeLatest(ActionTypes.SUBMIT_LOCATION_CONFIG, doSubmitLocationConfig);
}

export default function* watchAuthAll() {
  yield all([
    watchCreateTrafficConfig(),
    watchSubmitLocationConfig(),
    watchLocallySaveConfigLocation(),
    watchRequestLocationConfig(),
  ]);
}
