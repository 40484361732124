import React from 'react';
import { Skeleton } from '@uxui/skeleton-loader';
import { SkeletonTable, SkeletonTextBlock } from '@aha/ui';
import {
  ChartContainer,
  Container,
  TableContainer,
} from 'components/HotelChartDetailStyle';

const ChartDetailLoader = () => {
  return (
    <>
      <Container>
        <ChartContainer>
          <SkeletonTextBlock />
        </ChartContainer>
        <TableContainer>
          <div className="p-2">
            <SkeletonTable
              numOfRow={5}
              columms={[
                { width: '50%', loaderWidth: '80%' },
                { width: '50%', loaderWidth: '100%' },
              ]}
            ></SkeletonTable>
          </div>
        </TableContainer>
      </Container>
    </>
  );
};

export default ChartDetailLoader;
