const ROUTES = {
  LOGIN: '/start/login',
  HOME: '/',
  HOTELS: '/hotels',
  HOTELS_ID: '/hotels/:hotelRefCode',
  HOTELS_ID_BUDGET: '/hotels/:hotelRefCode/budget',
  HOTELS_ID_BUDGET_REVENUE: '/hotels/:hotelRefCode/budget/revenue',
  HOTELS_ID_BUDGET_ADR: '/hotels/:hotelRefCode/budget/adr',
  HOTELS_ID_BUDGET_RMNS: '/hotels/:hotelRefCode/budget/rmns',
  HOTELS_ID_OCC: '/hotels/:hotelRefCode/occ',
  HOTELS_ID_OCC_DETAIL: '/hotels/:hotelRefCode/occ/detail',
  HOTELS_ID_OCC_RMNS: '/hotels/:hotelRefCode/occ/rmns',
  HOTELS_ID_OCC_ADR: '/hotels/:hotelRefCode/occ/adr/:type',
  HOTELS_ID_SETTING: '/hotels/:hotelRefCode/settings',
  HOTELS_ID_SETTING_BUDGET: '/hotels/:hotelRefCode/settings/budget',
  HOTELS_ID_SETTING_BUDGET_DETAIL:
    '/hotels/:hotelRefCode/settings/budget/:year',
  HOTELS_ID_SETTING_BUDGET_DETAIL_EDIT:
    '/hotels/:hotelRefCode/settings/budget/:year/edit',
  HOTELS_ID_SETTING_BEST_RATE: '/hotels/:hotelRefCode/settings/best-rate',
  HOTELS_ID_SETTING_COMPSETS: '/hotels/:hotelRefCode/settings/compsets',
  HOTELS_ID_SETTING_EVENTS: '/hotels/:hotelRefCode/settings/events',
  LOCATIONS: '/locations',
  LOCATIONS_ID: '/locations/:locationRefCode',
  LOCATIONS_ID_SETTING: '/locations/:locationRefCode/settings',
  LOCATIONS_ID_SETTING_TRAFFIC: '/locations/:locationRefCode/settings/traffic',
  LOCATIONS_ID_SETTING_TRAFFIC_EDIT:
    '/locations/:locationRefCode/settings/traffic/edit/:year',
  LOCATIONS_ID_SETTING_BOOKING_WINDOW:
    '/locations/:locationRefCode/settings/booking-window',
  LOCATIONS_ID_SETTING_BOOKING_WINDOW_YEAR:
    '/locations/:locationRefCode/settings/booking-window/:year',
  LOCATIONS_ID_SETTING_BOOKING_WINDOW_EDIT:
    '/locations/:locationRefCode/settings/booking-window/:year/edit',
  LOCATIONS_ID_SETTING_EVENTS: '/locations/:locationRefCode/settings/events',
  LOCATIONS_ID_SETTING_HOTELS: '/locations/:locationRefCode/settings/hotels',
  BEST_AVAILABLE_RATE_SETTING: '/settings/best-available-rate',
  COMPSETS_SETTING: '/settings/compsets',
  EVENTS_SETTING: '/settings/events',
  SYSTEM_SETUP: '/system-setup',
  SELECT_LOCATION: '/select-location',
  CONFIGURATION: '/config',
  CONFIGURATION_LOCATIONS: '/config/locations',
  CONFIGURATION_LOCATION_ID: '/config/locations/:locationRefCode',
  CONFIGURATION_HOTELS: '/config/locations/:locationRefCode/hotels',
  CONFIGURATION_HOTEL_ID:
    '/config/locations/:locationRefCode/hotels/:hotelRefCode',
};

export default ROUTES;
