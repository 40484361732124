declare global {
  interface Window {
    AHA: any;
  }
}

export default function injectAppContext() {
  if (typeof window === 'object') {
    window.AHA = {
      name: (process.env.REACT_APP_NAME || '').replace('@aha/', ''),
      version: process.env.REACT_APP_VERSION,
      stage: process.env.NODE_ENV === 'production' ? 'prod' : 'dev',
    };
  }
}
