import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ApplicationRootState } from 'types/app';
import {
  makeSelectDashboardHotelId,
  makeSelectDashboardHotelRefCode,
  makeSelectDashboardHotel,
  makeSelectDashboardHotelCityId,
} from './selectors';
import { ViewmodelHotel } from 'types/rms-schema';

const mapStateToProps = createStructuredSelector<
  ApplicationRootState,
  {
    hotel: ViewmodelHotel | null;
    hotelId: string;
    hotelCityId: string;
    hotelRefCode: string;
  }
>({
  hotel: makeSelectDashboardHotel(),
  hotelId: makeSelectDashboardHotelId(),
  hotelRefCode: makeSelectDashboardHotelRefCode(),
  hotelCityId: makeSelectDashboardHotelCityId(),
});

export default function useSelectDashboardHotel() {
  return useSelector(mapStateToProps);
}
