import { action } from 'typesafe-actions';
import ActionTypes from './constants';
import { ViewmodelHotel } from 'types/rms-schema';

export const setDashboardHotelId = (hotelId: string) =>
  action(ActionTypes.SET_DASHBOARD_HOTEL_ID, hotelId);

export const fetchDashboardHotel = (hotelID: any) => {
  return action(ActionTypes.FETCH_DASHBOARD_HOTEL_INFO, hotelID);
};

export const fetchDashboardHotelSuccess = (hotel: ViewmodelHotel | null) =>
  action(ActionTypes.FETCH_DASHBOARD_HOTEL_INFO_SUCCESS, hotel);

export const fetchDashboardHotelFailure = (error: Error) =>
  action(ActionTypes.FETCH_DASHBOARD_HOTEL_INFO_FAILURE, error);
