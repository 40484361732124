enum ActionTypes {
  SET_LOCATION_CONFIG_CITY_ID = 'locationConfig/SET_LOCATION_CONFIG_CITY_ID',
  SUBMIT_LOCATION_CONFIG = 'locationConfig/SUBMIT_LOCATION_CONFIG',
  SUBMIT_LOCATION_CONFIG_SUCCESS = 'locationConfig/SUBMIT_LOCATION_CONFIG_SUCCESS',
  RESET_LOCATION_CONFIG = 'locationConfig/RESET_LOCATION_CONFIG',

  SET_TRAFFIC_WIZARD_CURRENT_STEP = 'locationConfig/SET_TRAFFIC_WIZARD_CURRENT_STEP',

  UPDATE_TRAFFIC = 'locationConfig/UPDATE_TRAFFIC',
  UPDATE_BOOKING_WINDOW = 'locationConfig/UPDATE_BOOKING_WINDOW',
  CREATE_EVENTS = 'locationConfig/CREATE_EVENTS',
  SET_TRAFFIC_CONFIG_SUBMITTING = 'locationConfig/SET_TRAFFIC_CONFIG_SUBMITTING',

  CREATE_TRAFFIC_CONFIG = 'locationConfig/CREATE_TRAFFIC_CONFIG',
  CREATE_TRAFFIC_CONFIG_SUCCESS = 'locationConfig/CREATE_TRAFFIC_CONFIG_SUCCESS',
  CREATE_TRAFFIC_CONFIG_FAILURE = 'locationConfig/CREATE_TRAFFIC_CONFIG_FAILURE',

  SAVE_CONFIG_LOCATION_TO_LOCAL = 'locationConfig/SAVE_CONFIG_LOCATION_TO_LOCAL',
  REQUEST_PERSIST_CONFIG_LOCATION = 'locationConfig/REQUEST_PERSIST_CONFIG_LOCATION',
  REQUEST_PERSIST_CONFIG_LOCATION_FAIL = 'locationConfig/REQUEST_PERSIST_CONFIG_LOCATION_FAIL',
  APPLY_PERSIST_CONFIG_LOCATION = 'locationConfig/APPLY_PERSIST_CONFIG_LOCATION',
}

export default ActionTypes;
