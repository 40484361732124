import React from 'react';
import tw from 'tailwind.macro';
import styled from 'styled-components';
import { Modal, Icon, Button } from '@aha/ui';
const Container = styled.div`
  ${tw`mb-3 ml-6 w-full flex items-center `}
  margin-top: -1.5rem !important;
`;

const IconStyled = styled.div`
  ${tw`flex items-center justify-center mr-2`}
`;

const TitleStyled = styled.div`
  ${tw`uppercase text-base text-center font-semibold`}
`;

const StyledModal = styled(Modal)`
  .ant-modal-footer > div > button:first-child {
    width: auto !important;
    text-transform: none !important;
  }
  .ant-modal-footer > div > button:last-child {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .ant-modal-content > button:first-child {
    display: none;
  }
`;

export interface SwitchPlaceModalProps {
  title: string;
  description: string;
  submitText: string;
  cancelText: string;
  visible?: boolean;
  onSave: () => void;
  onClose: () => void;
}

export interface InnerModalProps {
  title?: string;
  description?: string;
}

export const InnerModal = ({ description, title }: InnerModalProps) => (
  <>
    <Container>
      <IconStyled>
        <Icon type="exclamation-square" className="text-xl text-yellow" />
      </IconStyled>
      <TitleStyled data-testid="title_test">{title}</TitleStyled>
    </Container>
    <div className="mb-6 ml-6">{description}</div>
  </>
);

const SwitchPlaceModal = ({
  onSave,
  onClose,
  title,
  description,
  submitText,
  cancelText,
  visible = false,
}: SwitchPlaceModalProps) => {
  return (
    <StyledModal
      footer={
        <div className="h-full flex justify-end items-center">
          <Button className="px-9/2 h-8 w-32" onClick={onSave}>
            {submitText}
          </Button>
          <Button
            className="border-none bg-transparent text-secondary font-medium h-8 flex items-center ml-6 mr-2 "
            onClick={onClose}
          >
            {cancelText}
          </Button>
        </div>
      }
      visible={visible}
      width={600}
      maskClosable
      onCancel={onClose}
    >
      <InnerModal title={title} description={description} />
    </StyledModal>
  );
};

export default SwitchPlaceModal;
