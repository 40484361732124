import React, { Suspense } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import useSelectDashboardHotel from 'containers/Dashboard/useSelectDashboardHotel';
import SecondNavHeader from 'components/SecondNavHeader';
import moment from 'moment';
import { Select } from '@aha/ui';
import { formatNestedRoutePath } from '@aha/utils';
import ChartDetailLoader from 'components/Loaders/ChartDetailLoader';

const HotelBudgetDetailLayout: React.FC<RouteComponentProps> = ({
  children,
  location,
  path,
}) => {
  const { pathname } = location!;
  const { hotelRefCode } = useSelectDashboardHotel();

  const pathTokens = pathname.split('/');
  const title =
    pathTokens[pathTokens.length - 1] +
    ' Comparison: Budget vs Forecast vs Actual';

  const modifiedChildren = React.useMemo(
    // @ts-ignore
    () => formatNestedRoutePath(children, path),
    [children],
  );

  return (
    <>
      <SecondNavHeader
        title={title}
        rightRender={
          <div className="flex items-center text-grey-darker">
            <div className="uppercase mr-3 text-xs">Comparison</div>
            <Select
              className="font-medium text-secondary w-32 mr-12 "
              value={pathTokens[pathTokens.length - 1]}
              placeholder="Select budget type"
              onSelect={value => {
                navigate(`/hotels/${hotelRefCode}/budget/${value}`);
              }}
            >
              <Select.Option value="revenue">Revenue</Select.Option>
              <Select.Option value="adr">ADR</Select.Option>
              <Select.Option value="rmns">Rmns</Select.Option>
            </Select>
            <div className="text-grey-darker">
              Today: {moment().format('MMM Do, YYYY')}
            </div>
          </div>
        }
      ></SecondNavHeader>
      <Suspense fallback={<ChartDetailLoader />}>{modifiedChildren}</Suspense>
    </>
  );
};

export default HotelBudgetDetailLayout;
