import { LocationAction, LocationManagementState } from './types';
import ActionTypes from './constants';
import produce from 'immer';

export const initialState: LocationManagementState = {
  locationId: null,
};

const locationManagementState = (
  state: LocationManagementState = initialState,
  action: LocationAction,
): LocationManagementState =>
  produce(state, draft => {
    switch (action.type) {
      case ActionTypes.SET_LOCATION_MANAGEMENT_ID:
        draft.locationId = action.payload;
        break;
    }
  });

export default locationManagementState;
