import React from 'react';
import tw from 'tailwind.macro';
import styled from 'styled-components';
import { ReactComponent as LogoIconDark } from '../../svg/logo.svg';
import { ReactComponent as LogoIconWhite } from '../../svg/logo-white.svg';
import { ReactComponent as LogoTextDark } from '../../svg/logo-text.svg';
import { ReactComponent as LogoTextWhite } from '../../svg/logo-text-white.svg';
import { useMedia } from '@aha/utils';
import classnames from 'classnames';

export interface LogoProps {
  scheme?: 'dark' | 'light';
  hasText?: boolean;
  hiddenOnMobile?: boolean;
  onClick?: () => void;
}

const Container = styled.div`
  ${tw`items-center flex font-bold`};
  max-width: 100px;
  > svg:nth-child(2) {
    margin-left: 6px;
  }
`;

export const Logo = ({
  hasText = false,
  scheme = 'dark',
  hiddenOnMobile = false,
  onClick,
}: LogoProps) => {
  const LogoRender: any = scheme === 'dark' ? LogoIconDark : LogoIconWhite;
  const TextRender: any = scheme === 'dark' ? LogoTextDark : LogoTextWhite;

  const isOnMobile = useMedia('lg');

  return (
    <Container
      onClick={onClick}
      className={classnames({
        'cursor-pointer': onClick,
      })}
    >
      <LogoRender width="37" height="37" className="flex-none" />{' '}
      {hasText && ((!hiddenOnMobile && isOnMobile) || !isOnMobile) && (
        <TextRender />
      )}
    </Container>
  );
};

export default Logo;
