import axios from 'axios';
import NProgress from 'nprogress';
import { store } from '../App';
import actionTypes from 'containers/Auth/constants';

const coreAPI = axios.create({
  baseURL: process.env.REACT_APP_CORE_API || 'https://dev.backend.aharooms.net',
  timeout: 180000,
});

coreAPI.interceptors.request.use(
  function(config) {
    if (config.method !== 'get') {
      NProgress.start();
    }
    return config;
  },
  function(error) {
    NProgress.done();
    return Promise.reject(error);
  },
);

coreAPI.interceptors.response.use(
  function(response) {
    NProgress.done();
    return response.data;
  },
  function(error) {
    NProgress.done();
    if (error.response.status === 401) {
      // showErrorNotification('No Permission', error);
      store.dispatch({ type: actionTypes.LOG_OUT });
    }
    return Promise.reject(error);
  },
);

function setToken(token: string) {
  window.localStorage.setItem('token', token);
  coreAPI.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

function removeToken() {
  window.localStorage.removeItem('token');
}

const token = window.localStorage.getItem('token');

if (token) {
  setToken(token);
}

export { coreAPI, setToken, removeToken };
