import React, { useMemo } from 'react';
import { RouteComponentProps, Redirect } from '@reach/router';
import useSelectLocations from 'containers/SelectLocation/useSelectLocations';
import ROUTES from 'constants/routes';
import { formatNestedRoutePath } from '@aha/utils';
import { Suspense } from 'react';
import PageTableLoader from 'components/Loaders/PageTableLoader';
import ConfigWizardLoader from 'components/Loaders/ConfigWizardLoader';
import useSelectAreas from 'containers/LocationManagementHome/useSelectAreas';

const HotelConfigurationRoot: React.FC<RouteComponentProps<{
  locationRefCode: string;
}>> = ({ children, path, locationRefCode, location }) => {
  const modifiedChildren = useMemo(
    // @ts-ignore
    () => formatNestedRoutePath(children, path),
    [children],
  );

  // TODO: DO NOT REMOVE THE CODE, NOT COMMENT OUT TO SERVE DEV PURPOSE
  const { configuredAreas } = useSelectAreas();
  const isHotelListPage =
    location?.pathname.split('/').length ===
    ROUTES.CONFIGURATION_HOTELS.split('/').length;

  // In case the locations containing the hotel has not been configured,
  // then users should not be able to configure the hotels from the location

  const locationHasNotBeenConfigured =
    configuredAreas.findIndex(
      ({ ref_code }) => ref_code === locationRefCode,
    ) === -1;

  if (locationHasNotBeenConfigured) {
    return <Redirect to={ROUTES.LOCATIONS} noThrow />;
  }

  return (
    <Suspense
      fallback={
        isHotelListPage ? (
          <div className="-mt-5">
            <PageTableLoader />
          </div>
        ) : (
          <ConfigWizardLoader />
        )
      }
    >
      {modifiedChildren}
    </Suspense>
  );
};

export default HotelConfigurationRoot;
