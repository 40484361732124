import { useEffect, useState, useRef } from 'react';

export default function usePreLoading(delay: number = 150) {
  const [loading, setLoading] = useState(true);
  const timer = useRef(-1);
  useEffect(() => {
    timer.current = setTimeout(() => {
      setLoading(false);
    }, delay);

    return () => {
      clearTimeout(timer.current);
    };
  }, []); // eslint-disable-line

  return loading;
}
