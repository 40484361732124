import React, { Suspense } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import useSelectDashboardHotel from 'containers/Dashboard/useSelectDashboardHotel';
import SecondNavHeader from 'components/SecondNavHeader';
import { Select } from '@aha/ui';
import moment from 'moment';
import { formatNestedRoutePath } from '@aha/utils';
import ChartDetailLoader from 'components/Loaders/ChartDetailLoader';

const HotelOccDetailLayout: React.FC<RouteComponentProps> = ({
  children,
  location,
  path,
}) => {
  const modifiedChildren = React.useMemo(
    // @ts-ignore
    () => formatNestedRoutePath(children, path),
    [children],
  );

  const { pathname } = location!;
  const { hotelId, hotelRefCode } = useSelectDashboardHotel();

  const pathTokens = pathname.split('/');
  const initialValue =
    pathTokens[pathTokens.length - 1] === 'rmns'
      ? pathTokens[pathTokens.length - 1]
      : pathTokens[pathTokens.length - 2].toUpperCase();
  const title = initialValue + ' Comparison: Forecast vs Actual';
  return (
    <>
      <SecondNavHeader
        title={title}
        rightRender={
          <div className="flex items-center text-grey-darker">
            <div>Today: {moment().format('MMM Do, YYYY')}</div>
          </div>
        }
      ></SecondNavHeader>
      <Suspense fallback={<ChartDetailLoader />}>{modifiedChildren}</Suspense>
    </>
  );
};

export default HotelOccDetailLayout;
