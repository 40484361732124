import { action } from 'typesafe-actions';
import ActionTypes from './constants';
import {
  AreaHotelWithSelectedStatus,
  ViewmodelArea,
  AreaConfigRequest,
  AreaAssignHotelRequest,
} from 'types/rms-schema';
import { InjectPromise } from 'types/actions';

export const fetchAreas = () => action(ActionTypes.FETCH_AREAS);

export const fetchAreasSuccess = (areas: ViewmodelArea[]) =>
  action(ActionTypes.FETCH_AREAS_SUCCESS, areas);

export const fetchAreasFailure = (error: Error) =>
  action(ActionTypes.FETCH_AREAS_FAILURE, error);

export const createArea = (
  params: Required<InjectPromise<ViewmodelArea, string>>,
) => action(ActionTypes.CREATE_AREA, params);

export const createAreaSuccess = (area: ViewmodelArea) =>
  action(ActionTypes.CREATE_AREA_SUCCESS, area);

export const createAreaFailure = (error: Error) =>
  action(ActionTypes.CREATE_AREA_FAILURE, error);

export const deleteArea = (areaID: string) =>
  action(ActionTypes.DELETE_AREA, areaID);

export const deleteAreaSuccess = (areaID: string) =>
  action(ActionTypes.DELETE_AREA_SUCCESS, areaID);

export const deleteAreaFailure = (error: Error) =>
  action(ActionTypes.DELETE_AREA_FAILURE, error);

export const createAreaConfig = (params: AreaConfigRequest) =>
  action(ActionTypes.CREATE_AREA_CONFIG, params);

export const createAreaConfigSuccess = (status: string) =>
  action(ActionTypes.CREATE_AREA_CONFIG_SUCCESS, status);

export const createAreaConfigFailure = (error: Error) =>
  action(ActionTypes.CREATE_AREA_CONFIG_FAILURE, error);

export const fetchAreaHotels = (areaID: string) =>
  action(ActionTypes.FETCH_AREA_HOTELS, areaID);

export const fetchAreaHotelsSuccess = (areas: AreaHotelWithSelectedStatus[]) =>
  action(ActionTypes.FETCH_AREA_HOTELS_SUCCESS, areas);

export const fetchAreaHotelsFailure = (error: Error) =>
  action(ActionTypes.FETCH_AREA_HOTELS_FAILURE, error);

export const assignHotelsForArea = (
  params: Required<InjectPromise<any, AreaAssignHotelRequest>>,
) => action(ActionTypes.ASSIGN_HOTELS_FOR_AREA, params);

export const assignHotelsForAreaSuccess = (
  areaID: string,
  body: AreaAssignHotelRequest,
) => action(ActionTypes.ASSIGN_HOTELS_FOR_AREA_SUCCESS, { areaID, ...body });

export const setAreaIsConfigured = (areaID: string) =>
  action(ActionTypes.SET_AREA_IS_CONFIGURED, areaID);

export const setAreaToHaveHotelConfigured = (areaID: string) =>
  action(ActionTypes.SET_AREA_TO_HAVE_HOTEL_CONFIGURED, areaID);
