import styled from 'styled-components';
import tw from 'tailwind.macro';

export const Container = styled.div`
  ${tw`bg-white absolute`}
  top: 130px;
  left: 10px;
  right: 10px;
  bottom: 10px;
`;

export const ChartContainer = styled.div`
  ${tw`absolute`}
  left:0;
  right: 350px;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  padding: 30px 20px;
`;

export const TableContainer = styled.div`
  ${tw`absolute border-l border-grey-lighter`}
  width: 350px;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
`;
