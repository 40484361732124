import { action } from 'typesafe-actions';
import ActionTypes from './constants';
import {
  AreaTraffic,
  AreaBookingWindow,
  AreaEvent,
  AreaConfigRequest,
} from 'types/rms-schema';
import { LocationConfigStep, TrafficConfigFormState } from './types';
import { InjectPromise } from 'types/actions';

export const setLocationConfigStep = (stepId: LocationConfigStep) =>
  action(ActionTypes.SET_TRAFFIC_WIZARD_CURRENT_STEP, stepId);

export const setTrafficCityId = (id: string) =>
  action(ActionTypes.SET_LOCATION_CONFIG_CITY_ID, id);

export const updateTraffics = (traffics: AreaTraffic[]) =>
  action(ActionTypes.UPDATE_TRAFFIC, traffics);

export const updateBookingWindows = (bookingWindows: AreaBookingWindow[]) =>
  action(ActionTypes.UPDATE_BOOKING_WINDOW, bookingWindows);

export const updateEvents = (events: AreaEvent[]) =>
  action(ActionTypes.CREATE_EVENTS, events);

export const setTrafficConfigSubmitting = (isSubmitting: boolean) =>
  action(ActionTypes.SET_TRAFFIC_CONFIG_SUBMITTING, isSubmitting);

export const submitLocationTrafficConfig = (params: InjectPromise) =>
  action(ActionTypes.SUBMIT_LOCATION_CONFIG, params);

export const submitLocationTrafficConfigSuccess = () =>
  action(ActionTypes.SUBMIT_LOCATION_CONFIG_SUCCESS);

export const resetLocationConfig = () =>
  action(ActionTypes.RESET_LOCATION_CONFIG);

export const createTrafficConfig = ({
  bookingWindows,
  areaID,
  events,
  traffics,
}: AreaConfigRequest) =>
  action(ActionTypes.CREATE_TRAFFIC_CONFIG, {
    bookingWindows,
    areaID,
    events,
    traffics,
  });

export const createTrafficConfigSuccess = (trafficConfig: AreaTraffic) =>
  action(ActionTypes.CREATE_TRAFFIC_CONFIG_SUCCESS, trafficConfig);

export const createTrafficConfigFailure = (error: Error) =>
  action(ActionTypes.CREATE_TRAFFIC_CONFIG_FAILURE, error);

export interface ConfigLocationSaveParams {
  traffics?: AreaTraffic[];
  bookingWindows?: AreaBookingWindow[];
  events?: AreaEvent[];
}

export const saveConfigLocationToLocal = (params: ConfigLocationSaveParams) =>
  action(ActionTypes.SAVE_CONFIG_LOCATION_TO_LOCAL, params);

export const requestPersistConfigLocation = () =>
  action(ActionTypes.REQUEST_PERSIST_CONFIG_LOCATION);

export interface ApplyConfigLocationParams {
  currentStep: LocationConfigStep;
  form: TrafficConfigFormState;
}

export const applyPersistConfigLocation = (params: ApplyConfigLocationParams) =>
  action(ActionTypes.APPLY_PERSIST_CONFIG_LOCATION, params);

export const requestPersistConfigFail = () =>
  action(ActionTypes.REQUEST_PERSIST_CONFIG_LOCATION_FAIL);
