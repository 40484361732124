import React, { Suspense, useMemo } from 'react';
import { RouteComponentProps, Link, Redirect } from '@reach/router';
import SecondNavHeader from 'components/SecondNavHeader';
import { formatRoute, formatNestedRoutePath } from '@aha/utils';
import ROUTES from 'constants/routes';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import LocationDropdown from 'containers/SelectLocation/LocationDropdown';
import SettingPaneLoader from 'components/Loaders/SettingPaneLoader';
import useSelectAreas from 'containers/LocationManagementHome/useSelectAreas';

const StyledLink = styled(Link)<{ active: number }>`
  ${tw`h-full inline-flex items-center relative hover:text-primary`}
  color: ${props => (props.active ? 'var(--primary)' : 'var(--secondary)')};
  font-weight: ${props => (props.active ? '500' : '400')};

  &:before {
    ${tw`absolute bg-primary`}
    content: '';
    height: 3px;
    bottom: 0;
    left: ${props => (props.active ? '0' : '50%')};
    right: ${props => (props.active ? '0' : '50%')};
    transition: all 0.2s ease;
  }
`;

const LocationSettingLayout: React.FC<RouteComponentProps<{
  locationRefCode: string;
}>> = ({ children, location, path, locationRefCode }) => {
  const { pathname } = location!;
  const { configuredAreas } = useSelectAreas();
  const modifiedChildren = useMemo(
    // @ts-ignore
    () => formatNestedRoutePath(children, path),
    [children],
  );

  const pathReplace = pathname.replace(locationRefCode!, ':locationRefCode');

  const areaInfo = configuredAreas.find(a => a.ref_code === locationRefCode);

  if (!locationRefCode || !areaInfo) {
    return <Redirect to={ROUTES.LOCATIONS} />;
  }

  const { totalHotelConfigured, totalHotelNotConfigured, name } = areaInfo!;
  const hotelSettingRoute = formatRoute(
    ROUTES.LOCATIONS_ID_SETTING_HOTELS,
    locationRefCode,
  );

  return (
    <div>
      <SecondNavHeader
        title="System Setup"
        leftRender={
          <div className="h-full">
            <div className="px-5 inline-flex h-full">
              <StyledLink
                to={formatRoute(
                  ROUTES.LOCATIONS_ID_SETTING_TRAFFIC,
                  locationRefCode,
                )}
                active={!pathname.includes('hotels') ? 1 : 0}
              >
                General
              </StyledLink>
            </div>
            <div className="px-5 inline-flex h-full">
              <StyledLink
                to={hotelSettingRoute}
                active={pathname === hotelSettingRoute ? 1 : 0}
              >
                Hotels
              </StyledLink>
            </div>
          </div>
        }
        rightRender={
          <div className="flex items-center">
            <LocationDropdown
              areas={configuredAreas}
              current={areaInfo!}
              className="mr-4"
              route={pathReplace}
            />
            <span className="text-grey-darker px-2">
              {Number(totalHotelConfigured) + Number(totalHotelNotConfigured)}{' '}
              Hotels
            </span>
            <span className="text-grey-darker px-2">
              {Number(totalHotelNotConfigured)} Unconfigured
            </span>
            <span className="text-grey-darker px-2">
              {Number(totalHotelConfigured)} Configured
            </span>
          </div>
        }
      />
      <Suspense fallback={<SettingPaneLoader />}>{modifiedChildren}</Suspense>
    </div>
  );
};

export default LocationSettingLayout;
