import { action } from 'typesafe-actions';
import ActionTypes from './constants';
import {
  ViewmodelBookingWindow,
  BookingwindowBookingWindowDetail,
} from 'types/rms-schema';
import { InjectPromise } from 'types/actions';

export const fetchLocationBookingSettingByYear = (
  locationId: string,
  year: number,
  month: number,
) =>
  action(ActionTypes.FETCH_LOCATION_SETTING_BOOKING_BY_MONTH, {
    locationId,
    year,
    month,
  });

export const fetchLocationBookingSettingSuccess = (
  bookingData: ViewmodelBookingWindow[],
) => action(ActionTypes.FETCH_LOCATION_SETTING_BOOKING_SUCCESS, bookingData);

export const fetchLocationBookingSettingFailure = (error: Error) =>
  action(ActionTypes.FETCH_LOCATION_SETTING_BOOKING_FAILURE, error);

export const updateLocationBookingSetting = (
  params: Required<
    InjectPromise<
      ViewmodelBookingWindow[] | undefined,
      { locationID: string; body: BookingwindowBookingWindowDetail[] }
    >
  >,
) => action(ActionTypes.UPDATE_LOCATION_SETTING_BOOKING, params);
