import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ApplicationRootState } from 'types/app';
import { makeSelectLocationManagementId } from './selectors';

const mapStateToProps = createStructuredSelector<
  ApplicationRootState,
  {
    locationId: string | null;
  }
>({
  locationId: makeSelectLocationManagementId(),
});

export default function useSelectLocationManagementId() {
  return useSelector(mapStateToProps);
}
