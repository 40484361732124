import React, { Suspense } from 'react';
import { RouteComponentProps, Redirect } from '@reach/router';
import ROUTES from 'constants/routes';
import useAppIsReady from 'hooks/useAppIsReady';
import AppLoader from 'components/Loaders/AppLoader';

const AppContainer: React.FunctionComponent<RouteComponentProps> = ({
  children,
}) => {
  const { failedToFetch, isLoading, isLogin } = useAppIsReady();

  if (!isLogin) {
    return <Redirect to={ROUTES.LOGIN} noThrow />;
  }

  if (isLoading) {
    return <AppLoader />;
  }

  if (failedToFetch) {
    return null;
  }

  return (
    <main className="font-sans pt-14">
      <Suspense fallback={null}>{children}</Suspense>
    </main>
  );
};

export default AppContainer;
