import React from 'react';
import { Menu } from 'antd';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import { MenuProps as AntMenuProps } from 'antd/lib/menu';
import { Layout } from 'antd';
const { Sider } = Layout;

const StyledMenu = styled(Menu)`
  ${tw`relative`}
  border-right: 1px solid var(--grey-lightest) !important;
  background-color: var(--grey-lightest) !important;

  .ant-menu-item {
    overflow: visible !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    border-bottom: 1px solid var(--grey-lightest) !important;
    height: 50px !important;
    line-height: 50px !important;
    color: var(--secondary) !important;
    padding-left: 16px !important;
    transition: none;

    &::after {
      content: none;
    }
  }

  .ant-menu-item-selected {
    background-color: var(--white) !important;
    color: var(--primary) !important;
    font-weight: 500 !important;
    width: calc(100% + 1px) !important;
  }
`;

const StyledSider = styled(Sider)`
  background-color: var(--grey-lightest) !important;
`;

const StyledLayout = styled(Layout)<MenuProps>`
  min-height: ${props => {
    return props.height ? props.height : 'calc(100vh - 130px) !important';
  }};
`;

export interface NavItemProps {
  id: string;
  name: string;
  link: string;
}

export interface MenuProps extends AntMenuProps {
  navItems?: NavItemProps[];
  pathname?: string;
  onItemClick?: (item: NavItemProps) => void;
  selectedKey?: string;
  siderWidth?: number;
  children?: React.ReactNode;
  height?: string;
}

export const SiderMenu = ({
  navItems = [],
  selectedKey = '',
  onItemClick,
  pathname,
  children,
  siderWidth = 187,
  height,
  ...rest
}: MenuProps) => {
  return (
    <StyledLayout className="rounded-sm" height={height}>
      <StyledSider width={siderWidth}>
        <StyledMenu
          theme="dark"
          mode="inline"
          selectedKeys={[selectedKey]}
          {...rest}
        >
          {navItems.map(item => (
            <Menu.Item
              key={item.link}
              onClick={() =>
                typeof onItemClick === 'function' && onItemClick(item)
              }
            >
              {item.name}
            </Menu.Item>
          ))}
        </StyledMenu>
      </StyledSider>
      {children}
    </StyledLayout>
  );
};

export default SiderMenu;
