import { AreasState, AreasActions } from './types';
import ActionTypes from './constants';
import produce from 'immer';

export const initialState: AreasState = {
  isLoading: false,
  error: null,
  areas: null,
};

const areasReducer = (
  state: AreasState = initialState,
  action: AreasActions,
): AreasState =>
  produce(state, draft => {
    switch (action.type) {
      case ActionTypes.FETCH_AREAS:
        draft.isLoading = true;
        draft.error = null;
        break;
      case ActionTypes.FETCH_AREAS_SUCCESS:
        draft.isLoading = false;
        draft.areas = action.payload;
        break;
      case ActionTypes.FETCH_AREAS_FAILURE:
        draft.isLoading = false;
        break;

      case ActionTypes.CREATE_AREA_SUCCESS:
        if (draft.areas) {
          draft.areas.push(action.payload);
        } else {
          draft.areas = [action.payload];
        }
        break;

      case ActionTypes.DELETE_AREA_SUCCESS: {
        const idx = (draft.areas || []).findIndex(
          area => area.id === action.payload,
        );

        if (idx !== -1 && Array.isArray(draft.areas)) {
          draft.areas.splice(idx, 1);
        }
        break;
      }

      case ActionTypes.SET_AREA_IS_CONFIGURED: {
        const idx = (draft.areas || []).findIndex(
          ({ id, ref_code }) =>
            action.payload === id || action.payload === ref_code,
        );
        if (idx !== -1 && Array.isArray(draft.areas)) {
          draft.areas[idx].isConfigured = true;
        }
        break;
      }

      case ActionTypes.ASSIGN_HOTELS_FOR_AREA_SUCCESS: {
        const idx = (draft.areas || []).findIndex(
          area => area.id === action.payload.areaID,
        );

        if (idx !== -1 && Array.isArray(draft.areas)) {
          draft.areas[idx].totalHotelNotConfigured =
            action.payload.add?.length || 0;
        }
        break;
      }

      case ActionTypes.SET_AREA_TO_HAVE_HOTEL_CONFIGURED:
        const areaIndex = (draft.areas || []).findIndex(
          ({ id, ref_code }) =>
            action.payload === id || action.payload === ref_code,
        );
        if (areaIndex !== -1 && Array.isArray(draft.areas)) {
          const oldTotalHotelConfigured = draft.areas[areaIndex]
            .totalHotelConfigured as number;

          const oldTotalHotelNotConfigured = draft.areas[areaIndex]
            .totalHotelNotConfigured as number;

          draft.areas[areaIndex].totalHotelConfigured =
            oldTotalHotelConfigured + 1;

          draft.areas[areaIndex].totalHotelNotConfigured =
            (oldTotalHotelNotConfigured || 1) - 1;
        }
        break;
    }
  });

export default areasReducer;
