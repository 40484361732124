import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';

const Heading = styled.h3`
  ${tw`text-grey-darker text-base m-0 font-normal capitalize`}
`;

const SubHeading: React.FC<any> = ({ children, ...props }) => (
  <Heading {...props}>{children}</Heading>
);

export default SubHeading;
