import { ViewmodelArea } from 'types/rms-schema';
import { ApplicationRootState } from 'types/app';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectAreas,
  makeSelectConfiguredAreas,
  makeSelectUnconfiguredAreas,
  makeSelectConfiguredAreasHavingConfiguredHotel,
} from './selectors';

const mapStateToProps = createStructuredSelector<
  ApplicationRootState,
  {
    areas: ViewmodelArea[] | null;
    configuredAreas: ViewmodelArea[];
    unconfiguredAreas: ViewmodelArea[];
    configuredAreasHavingConfiguredHotel: ViewmodelArea[];
  }
>({
  areas: makeSelectAreas(),
  configuredAreas: makeSelectConfiguredAreas(),
  unconfiguredAreas: makeSelectUnconfiguredAreas(),
  configuredAreasHavingConfiguredHotel: makeSelectConfiguredAreasHavingConfiguredHotel(),
});

export default function useSelectAreas() {
  return useSelector(mapStateToProps);
}
