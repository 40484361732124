import React, { lazy } from 'react';
import { Router as ReachRouter, Redirect } from '@reach/router';
import ROUTES from './constants/routes';
import ComponentRoute from 'components/ComponentRoute';
import HotelSettingsLayout from 'containers/HotelSettingsLayout';
import LoginPage from 'containers/Auth/LoginPage';
import AppContainer from 'containers/AppContainer';
import Dashboard from 'containers/Dashboard';
import LocationManagement from 'containers/LocationManagement';
import Configuration from 'containers/Configuration';
import HotelConfigurationRoot from 'containers/HotelConfigurationRoot';
import LocationSettingLayout from 'containers/LocationSettingLayout';
import LocationSettingDetailLayout from 'containers/LocationSettingDetailLayout';
import HotelBudgetDetailLayout from 'containers/HotelBudgetDetailLayout';
import HotelOccDetailLayout from 'containers/HotelOccDetailLayout';
import NotFoundPage from 'containers/404Page';
import RedirectErrorRoute from 'components/RedirectErrorRoute';
import LocationSettingBookingWindowEdit from 'containers/LocationSettingBookingWindow/LocationSettingBookingWindowEdit';

const DashboardHome = lazy(() => import('containers/DashboardHome'));
const HotelSettingBudget = lazy(() => import('containers/HotelSettingBudget'));
const HotelSettingBudgetDetail = lazy(() =>
  import('containers/HotelSettingBudgetDetail'),
);
const HotelSettingBudgetDetailEdit = lazy(() =>
  import('containers/HotelSettingBudgetDetail/HotelSettingBudgetDetailEdit'),
);
const HotelSettingBestRate = lazy(() =>
  import('containers/HotelSettingBestRate'),
);
const HotelSettingCompsets = lazy(() =>
  import('containers/HotelSettingCompsets'),
);
const HotelSettingEvents = lazy(() => import('containers/HotelSettingEvents'));
const HotelConfiguration = lazy(() => import('containers/HotelConfiguration'));
const HotelListConfiguration = lazy(() =>
  import('containers/HotelListConfiguration'),
);
const LocationManagementHome = lazy(() =>
  import('containers/LocationManagementHome'),
);
const ConfigurationHome = lazy(() => import('containers/ConfigurationHome'));
const ConfigurationLocation = lazy(() =>
  import('containers/ConfigurationLocation'),
);
const LocationManagementHotels = lazy(() =>
  import('containers/LocationManagementHotels'),
);
const LocationSettingHotels = lazy(() =>
  import('containers/LocationSettingHotels'),
);
const LocationSettingTraffic = lazy(() =>
  import('containers/LocationSettingTraffic'),
);
const LocationSettingTrafficEdit = lazy(() =>
  import('containers/LocationSettingTraffic/LocationSettingTrafficEdit'),
);
const LocationSettingBookingWindow = lazy(() =>
  import('containers/LocationSettingBookingWindow'),
);
const LocationSettingEvents = lazy(() =>
  import('containers/LocationSettingEvents'),
);

const HotelBudgetRevenue = lazy(() => import('containers/HotelBudgetRevenue'));
const HotelBudgetAdr = lazy(() => import('containers/HotelBudgetAdr'));
const HotelBudgetRmns = lazy(() => import('containers/HotelBudgetRmns'));
const HotelOccAdr = lazy(() => import('containers/HotelOccAdr'));
const HotelOcc = lazy(() => import('containers/HotelOcc'));

const Routes = () => {
  return (
    <ReachRouter primary={false}>
      <ComponentRoute path={ROUTES.LOGIN} Component={LoginPage} />
      <Configuration path={ROUTES.CONFIGURATION}>
        <ComponentRoute
          path={ROUTES.CONFIGURATION + '/'}
          Component={ConfigurationHome}
        />
        <ComponentRoute
          path={ROUTES.CONFIGURATION_LOCATION_ID}
          Component={ConfigurationLocation}
        />
        <HotelConfigurationRoot path={ROUTES.CONFIGURATION_HOTELS}>
          <ComponentRoute
            path={ROUTES.CONFIGURATION_HOTELS}
            Component={HotelListConfiguration}
          />
          <ComponentRoute
            path={ROUTES.CONFIGURATION_HOTEL_ID}
            Component={HotelConfiguration}
          />
        </HotelConfigurationRoot>
        <ComponentRoute
          path={ROUTES.CONFIGURATION + '*'}
          Component={RedirectErrorRoute}
        />
      </Configuration>
      <AppContainer path="/">
        <Redirect from="/" to={ROUTES.LOCATIONS} noThrow></Redirect>
        <LocationManagement path={ROUTES.LOCATIONS}>
          <ComponentRoute
            path={ROUTES.LOCATIONS}
            Component={LocationManagementHome}
          />
          <ComponentRoute
            path={ROUTES.LOCATIONS_ID}
            Component={LocationManagementHotels}
          />
          <LocationSettingLayout path={ROUTES.LOCATIONS_ID_SETTING}>
            <ComponentRoute
              path={ROUTES.LOCATIONS_ID_SETTING_HOTELS}
              Component={LocationSettingHotels}
            ></ComponentRoute>
            <LocationSettingDetailLayout path={ROUTES.LOCATIONS_ID_SETTING}>
              <ComponentRoute
                path={ROUTES.LOCATIONS_ID_SETTING_TRAFFIC_EDIT}
                Component={LocationSettingTrafficEdit}
              ></ComponentRoute>
              <ComponentRoute
                path={ROUTES.LOCATIONS_ID_SETTING_TRAFFIC}
                Component={LocationSettingTraffic}
              ></ComponentRoute>
              <ComponentRoute
                path={ROUTES.LOCATIONS_ID_SETTING_BOOKING_WINDOW}
                Component={LocationSettingBookingWindow}
              ></ComponentRoute>
              <ComponentRoute
                path={ROUTES.LOCATIONS_ID_SETTING_BOOKING_WINDOW_YEAR}
                Component={LocationSettingBookingWindow}
              ></ComponentRoute>
              <ComponentRoute
                path={ROUTES.LOCATIONS_ID_SETTING_BOOKING_WINDOW_EDIT}
                Component={LocationSettingBookingWindowEdit}
              ></ComponentRoute>
              <ComponentRoute
                path={ROUTES.LOCATIONS_ID_SETTING_EVENTS}
                Component={LocationSettingEvents}
              ></ComponentRoute>
              <ComponentRoute
                path={ROUTES.LOCATIONS_ID_SETTING + '*'}
                Component={RedirectErrorRoute}
              />
            </LocationSettingDetailLayout>
            <ComponentRoute
              path={ROUTES.LOCATIONS_ID_SETTING + '*'}
              Component={RedirectErrorRoute}
            />
          </LocationSettingLayout>
          <ComponentRoute
            path={ROUTES.LOCATIONS + '*'}
            Component={RedirectErrorRoute}
          />
        </LocationManagement>
        <Dashboard path={ROUTES.HOTELS}>
          <ComponentRoute path={ROUTES.HOTELS_ID} Component={DashboardHome} />
          <HotelBudgetDetailLayout path={ROUTES.HOTELS_ID_BUDGET}>
            <ComponentRoute
              path={ROUTES.HOTELS_ID_BUDGET_REVENUE}
              Component={HotelBudgetRevenue}
            ></ComponentRoute>
            <ComponentRoute
              path={ROUTES.HOTELS_ID_BUDGET_ADR}
              Component={HotelBudgetAdr}
            ></ComponentRoute>
            <ComponentRoute
              path={ROUTES.HOTELS_ID_BUDGET_RMNS}
              Component={HotelBudgetRmns}
            ></ComponentRoute>
          </HotelBudgetDetailLayout>
          <HotelOccDetailLayout path={ROUTES.HOTELS_ID_OCC}>
            <ComponentRoute
              path={ROUTES.HOTELS_ID_OCC_ADR}
              Component={HotelOccAdr}
            ></ComponentRoute>
            <ComponentRoute
              path={ROUTES.HOTELS_ID_OCC_DETAIL}
              Component={HotelOcc}
            ></ComponentRoute>
          </HotelOccDetailLayout>
          <HotelSettingsLayout path={ROUTES.HOTELS_ID_SETTING}>
            <ComponentRoute
              path={ROUTES.HOTELS_ID_SETTING_BUDGET_DETAIL_EDIT}
              Component={HotelSettingBudgetDetailEdit}
            />
            <ComponentRoute
              path={ROUTES.HOTELS_ID_SETTING_BUDGET_DETAIL}
              Component={HotelSettingBudgetDetail}
            />
            <ComponentRoute
              path={ROUTES.HOTELS_ID_SETTING_BUDGET}
              Component={HotelSettingBudget}
            />
            <ComponentRoute
              path={ROUTES.HOTELS_ID_SETTING_BEST_RATE}
              Component={HotelSettingBestRate}
            />
            <ComponentRoute
              path={ROUTES.HOTELS_ID_SETTING_COMPSETS}
              Component={HotelSettingCompsets}
            />
            <ComponentRoute
              path={ROUTES.HOTELS_ID_SETTING_EVENTS}
              Component={HotelSettingEvents}
            />
            <ComponentRoute
              path={ROUTES.HOTELS_ID_SETTING + '*'}
              Component={RedirectErrorRoute}
            />
          </HotelSettingsLayout>
          <ComponentRoute
            path={ROUTES.HOTELS + '*'}
            Component={RedirectErrorRoute}
          />
        </Dashboard>
        <ComponentRoute path="/*" Component={NotFoundPage} />
      </AppContainer>
    </ReachRouter>
  );
};

export default Routes;
