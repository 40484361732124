import React, { Suspense, useMemo } from 'react';
import { RouteComponentProps, Redirect } from '@reach/router';
import ROUTES from 'constants/routes';
import GlobalHeader from 'components/GlobalHeader';
import { ErrorBoundary } from '@aha/ui';
import useAppIsReady from 'hooks/useAppIsReady';
import { formatNestedRoutePath } from '@aha/utils';
import ConfigWizardLoader from 'components/Loaders/ConfigWizardLoader';

const Configuration: React.FunctionComponent<RouteComponentProps> = ({
  children,
  path,
  location,
}) => {
  const { isLoading, isLogin, failedToFetch } = useAppIsReady();

  const modifiedChildren = useMemo(
    // @ts-ignore
    () => formatNestedRoutePath(children, path),
    [children],
  );

  if (!isLogin) {
    return <Redirect to={ROUTES.LOGIN} noThrow />;
  }

  if (failedToFetch) {
    return null;
  }

  return (
    <main className="font-sans pt-14">
      <ErrorBoundary>
        <GlobalHeader />
        <div className="py-5 px-8">
          {isLoading ? (
            <ConfigWizardLoader />
          ) : (
            <Suspense fallback={<ConfigWizardLoader />}>
              {modifiedChildren}
            </Suspense>
          )}
        </div>
      </ErrorBoundary>
    </main>
  );
};

export default Configuration;
