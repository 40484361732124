import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';

const Wrapper = styled.a<{ active: boolean }>`
  ${tw`inline-flex items-center justify-center px-4`};
  height: 56px;
  color: ${props => (props.active ? 'var(--primary)' : 'var(--grey-darker)')};
  background-color: ${props =>
    props.active ? 'var(--grey-lightest)' : 'transparent'};
`;

export interface NavItemProps {
  active?: boolean;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  className?: string;
}

const NavItem: React.FC<NavItemProps> = ({
  active = false,
  children,
  ...rest
}) => {
  return (
    <Wrapper data-testid="nav-item" active={active} {...rest}>
      {children}
    </Wrapper>
  );
};

export default NavItem;
