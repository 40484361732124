import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectIsLogin, makeSelectLoginError } from './selectors';
import { ApplicationRootState } from 'types/app';
import { Redirect } from '@reach/router';
import ROUTES from 'constants/routes';
import { login } from './actions';
import { useInjectSaga } from 'utils/injectSaga';
import authSaga from './saga';
import { LoginLayout, LoginForm } from '@aha/ui';
import loginBackground from 'img/login-illustration.jpg';
import { HttpLoginRequest } from 'types/rms-schema';

const mapDispatchToProps = (dispatch: any) => ({
  doLogin: (values: HttpLoginRequest) => dispatch(login(values)),
});

const mapStateToProps = createStructuredSelector<
  ApplicationRootState,
  { isLogin: boolean; error: string }
>({
  isLogin: makeSelectIsLogin(),
  error: makeSelectLoginError(),
});

export default function LoginPage() {
  useInjectSaga({ key: 'auth', saga: authSaga });

  const { isLogin, error } = useSelector(mapStateToProps);
  const dispatch = useDispatch();
  const { doLogin } = mapDispatchToProps(dispatch);

  if (isLogin) {
    return <Redirect to={ROUTES.HOME} noThrow />;
  }

  return (
    <LoginLayout
      name="Revenue Management System"
      description="Revenue management is the practice of selling the right room, to the right customer, at the right time, for the right price, via the right distribution channel and a Revenue Management System can assist with each of these aspects. RMS software solutions can help to calculate ideal room rates, allow rates to be adjusted quickly, provide projections of future revenue and profit, and offer information about competitors, allowing for a more strategic approach."
      image={loginBackground}
    >
      <div className="px-14 flex items-center h-full">
        <LoginForm onSubmit={doLogin} error={error} />
      </div>
    </LoginLayout>
  );
}
