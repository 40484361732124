import { call, put, takeLatest, all } from 'redux-saga/effects';
import { coreAPI } from 'utils/request';
import ActionTypes from './constants';

import {
  TrafficdemandListResponse,
  BookingwindowBookingWindowListResponse,
} from 'types/rms-schema';
import { showErrorNotification } from '@aha/utils';
import {
  fetchLocationBookingSettingSuccess,
  fetchLocationBookingSettingFailure,
} from './actions';
import { ExtractAction } from 'types/actions';
import { LocationBookingSettingAction } from './types';

export function* fetchLocationBookingSettingByYear(
  action: ExtractAction<
    LocationBookingSettingAction,
    ActionTypes.FETCH_LOCATION_SETTING_BOOKING_BY_MONTH
  >,
) {
  const { payload } = action;
  const { locationId, year, month } = payload;
  try {
    const { data }: TrafficdemandListResponse = yield call(
      coreAPI.get,
      `/v1/rms/configs/areas/${locationId}/booking-windows?year=${year}&month=${month}`,
    );
    yield put(fetchLocationBookingSettingSuccess(data!));
  } catch (err) {
    yield put(fetchLocationBookingSettingFailure(err));
    showErrorNotification('Fail to fetch', err);
  }
}

export function* updateLocationBookingSettingByYear(
  action: ExtractAction<
    LocationBookingSettingAction,
    ActionTypes.UPDATE_LOCATION_SETTING_BOOKING
  >,
) {
  const {
    payload: {
      data: { locationID, body },
      resolve,
      reject,
    },
  } = action;
  try {
    const {
      data,
    }: BookingwindowBookingWindowListResponse = yield call(
      coreAPI.put,
      `/v1/rms/configs/areas/${locationID}/booking-windows`,
      { bookingWindows: body },
    );

    resolve(data);
  } catch (err) {
    showErrorNotification('Fail to update booking window', err);
    reject();
  }
}

export function* watchUpdateLocationBookingWindowSetting() {
  yield takeLatest(
    ActionTypes.UPDATE_LOCATION_SETTING_BOOKING,
    updateLocationBookingSettingByYear,
  );
}

export function* watchFetchLocationBookingByYear() {
  yield takeLatest(
    ActionTypes.FETCH_LOCATION_SETTING_BOOKING_BY_MONTH,
    fetchLocationBookingSettingByYear,
  );
}

export default function* watchLocationBookingWindowSettingAll() {
  yield all([
    watchFetchLocationBookingByYear(),
    watchUpdateLocationBookingWindowSetting(),
  ]);
}
