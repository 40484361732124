import React from 'react';
import { Dropdown, Menu } from 'antd';
import { Icon } from '@aha/ui';
import { ViewmodelArea } from 'types/rms-schema';
import { ClickParam } from 'antd/lib/menu';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import { navigate } from '@reach/router';
import { formatRoute } from '@aha/utils';
import ROUTES from 'constants/routes';

const StyledMenu = styled(Menu)`
  ${tw`overflow-y-auto`}
  max-height: 305px;
  .ant-menu-item {
    ${tw`flex items-center h-8 text-secondary relative z-99`};
    padding: 11px 16px !important;
    width: 230px;
    min-height: 21px;
    height: 43px;
    margin: 0 !important;
  }
`;

interface OptionIconProps {
  label?: string;
  isTick?: boolean;
}

interface LocationDropdownProps {
  areas: ViewmodelArea[];
  current: ViewmodelArea;
  className?: string;
  route?: string;
}

function OptionIcon({ label, isTick }: OptionIconProps) {
  return (
    <div className="flex items-center w-full">
      {label}
      {isTick && (
        <Icon
          className="ml-auto text-sm anticon-check ant-select-selected-icon"
          type="check"
        />
      )}
    </div>
  );
}

const LocationDropdown = ({
  areas,
  current,
  className = '',
  route = ROUTES.LOCATIONS_ID,
}: LocationDropdownProps) => {
  return (
    <>
      <Dropdown
        overlayClassName="shadow"
        overlay={
          <>
            <div className="w-full flex items-center h-full py-2 bg-white px-3 text-3xs">
              <Icon
                type="map-marker-alt"
                className="text-grey-darker mr-1 text-2xl"
              />
              <span className="text-grey-darker uppercase text-2xs leading-none">
                Your area
              </span>
            </div>
            <StyledMenu
              onClick={({ key }: ClickParam) =>
                navigate(formatRoute(route, key), {
                  replace: false,
                })
              }
            >
              {areas.map(area => (
                <Menu.Item key={area.ref_code}>
                  <OptionIcon
                    label={area.name}
                    isTick={area.id === current.id}
                  ></OptionIcon>
                </Menu.Item>
              ))}
            </StyledMenu>
          </>
        }
      >
        <div className={`flex cursor-pointer relative ${className}`}>
          <div className="absolute pin"></div>
          <div className="text-secondary font-medium">{current.name}</div>
          <div className="ml-2 w-6 flex justify-center items-center text-3xs">
            <Icon type="sort-down" className="mt-1" />
          </div>
        </div>
      </Dropdown>
    </>
  );
};

export default LocationDropdown;
