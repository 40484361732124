import { action } from 'typesafe-actions';
import ActionTypes from './constants';
import { HttpLoginRequest, ViewmodelUser } from 'types/rms-schema';

export const login = (request: HttpLoginRequest) =>
  action(ActionTypes.LOG_IN, request);

export const setLoginFailure = (error: string) =>
  action(ActionTypes.LOG_IN_FAILURE, error);

export const setLoginSuccess = (user: ViewmodelUser) =>
  action(ActionTypes.LOG_IN_SUCCESS, user);

export const fetchUser = () => action(ActionTypes.FETCH_USER);

export const fetchUserSuccess = (user: ViewmodelUser) =>
  action(ActionTypes.FETCH_USER_SUCCESS, user);

export const logout = () => action(ActionTypes.LOG_OUT);
