import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import { Skeleton } from '@uxui/skeleton-loader';
import { SkeletonTextBlock } from '@aha/ui';

const Container = styled.div`
  font-size: 0;
`;

const Pane = styled.div`
  ${tw`flex`}
  height: calc(100vh - 140px);
  > div:first-of-type {
    width: 186px;
  }

  > div:last-of-type {
    width: calc(100% - 186px);
  }
`;

export const ConfigWizardRightBlockLoader = () => (
  <SkeletonTextBlock blocks={['200px', '160px', '220px', '100px', '150px']} />
);

const ConfigWizardLoader = () => {
  return (
    <Container>
      <div className="flex items-center justify-between pb-5">
        <div>
          <Skeleton
            width="200px"
            height="19px"
            baseColor="#ddd"
            highlightColor="#eee"
          />
          <Skeleton
            className="ml-4"
            width="100px"
            height="19px"
            baseColor="#ddd"
            highlightColor="#eee"
          />
        </div>

        <div>
          <Skeleton
            className="ml-4"
            width="100px"
            height="14px"
            baseColor="#ddd"
            highlightColor="#eee"
          />
        </div>
      </div>

      <Pane>
        <div className="bg-grey-lightest px-6 py-5">
          <SkeletonTextBlock blocks={['100px', '80px', '80px']} />
        </div>
        <div className="bg-white px-8 py-5">
          <ConfigWizardRightBlockLoader />
        </div>
      </Pane>
    </Container>
  );
};

export default ConfigWizardLoader;
