import React from 'react';

function formatNestedRoutePath(
  children: React.ReactElement<{
    children: React.ReactElement<{ path: string }>[];
  }>,
  path: string,
) {
  const clonedChildren = React.Children.map(children.props.children, child => {
    const startIndex = child.props.path.indexOf(path) + path.length;

    return React.cloneElement(child, {
      ...child.props,
      path:
        startIndex === child.props.path.length
          ? '/'
          : child.props.path.substring(startIndex),
    });
  });

  return React.cloneElement(children, {
    ...children.props,
    children: clonedChildren,
  });
}

export default formatNestedRoutePath;
