import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types/app';
import { initialState } from './reducer';

const selectDashboardState = (state: ApplicationRootState) => {
  return state.dashboard || initialState;
};

const makeSelectDashboardHotelId = () =>
  createSelector(selectDashboardState, dashboardState => {
    return dashboardState.hotel?.id || '';
  });

const makeSelectDashboardHotelLoading = () =>
  createSelector(selectDashboardState, hotelsState => hotelsState.loading);

const makeSelectDashboardHotelError = () =>
  createSelector(selectDashboardState, hotelsState => hotelsState.error);

const makeSelectDashboardHotel = () =>
  createSelector(selectDashboardState, hotelsState => hotelsState.hotel);

const makeSelectDashboardHotelRefCode = () =>
  createSelector(
    selectDashboardState,
    dashboardState => dashboardState.hotel?.refCode || '',
  );

const makeSelectDashboardHotelCityId = () =>
  createSelector(
    selectDashboardState,
    dashboardState => dashboardState.hotel?.areaID || '',
  );

export {
  makeSelectDashboardHotelId,
  makeSelectDashboardHotel,
  makeSelectDashboardHotelError,
  makeSelectDashboardHotelLoading,
  makeSelectDashboardHotelRefCode,
  makeSelectDashboardHotelCityId,
};
