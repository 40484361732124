import { LocationConfigState, TrafficConfigActions } from './types';
import ActionTypes from './constants';
import produce from 'immer';

export const initialState: LocationConfigState = {
  form: {
    traffics: [],
    bookingWindows: [],
    events: [],
    areaID: '',
  },
  locationId: '',
  currentStep: 'step-1',
  submitSuccess: false,
  isSubmitting: false,
  loading: false,
  requestingLocalStorage: true,
  error: null,
};

const trafficConfigReducer = (
  state: LocationConfigState = initialState,
  action: TrafficConfigActions,
): LocationConfigState =>
  produce(state, draft => {
    switch (action.type) {
      case ActionTypes.REQUEST_PERSIST_CONFIG_LOCATION_FAIL:
        draft.requestingLocalStorage = false;
        break;
      case ActionTypes.APPLY_PERSIST_CONFIG_LOCATION:
        draft.currentStep = action.payload.currentStep;
        draft.form = action.payload.form;
        draft.requestingLocalStorage = false;
        break;
      case ActionTypes.SET_TRAFFIC_WIZARD_CURRENT_STEP:
        draft.currentStep = action.payload;
        break;
      case ActionTypes.SET_LOCATION_CONFIG_CITY_ID:
        draft.form.areaID = action.payload;
        draft.locationId = action.payload;
        break;
      case ActionTypes.UPDATE_TRAFFIC:
        draft.form.traffics = action.payload;
        break;
      case ActionTypes.UPDATE_BOOKING_WINDOW:
        draft.form.bookingWindows = action.payload;
        break;
      case ActionTypes.CREATE_EVENTS:
        draft.form.events = action.payload;
        break;
      case ActionTypes.SET_TRAFFIC_CONFIG_SUBMITTING:
        draft.isSubmitting = action.payload;
        break;
      case ActionTypes.SUBMIT_LOCATION_CONFIG:
        draft.isSubmitting = true;
        break;
      case ActionTypes.SUBMIT_LOCATION_CONFIG_SUCCESS:
        draft.isSubmitting = false;
        draft.submitSuccess = true;
        draft.form = { ...initialState.form };
        break;
      case ActionTypes.RESET_LOCATION_CONFIG:
        draft.submitSuccess = false;
        draft.isSubmitting = false;
        draft.currentStep = initialState.currentStep;
        draft.form = { ...initialState.form };
        break;
    }
  });

export default trafficConfigReducer;
