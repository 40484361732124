import React from 'react';
import { Exception } from '@aha/ui';
import errorImageUrl from 'svg/error.svg';

export default function NotFoundPage() {
  return (
    <Exception
      message="404 Error"
      image={errorImageUrl}
      titleButton="Back to Home"
    />
  );
}
