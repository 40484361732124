import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types/app';
import { initialState } from './reducer';

const selectAreasState = (state: ApplicationRootState) => {
  return state.areas || initialState;
};

const makeSelectAreasLoading = () =>
  createSelector(selectAreasState, state => state.isLoading);

const makeSelectAreasError = () =>
  createSelector(selectAreasState, state => state.error);

const makeSelectAreas = () =>
  createSelector(selectAreasState, state => state.areas);

const makeSelectConfiguredAreas = () =>
  createSelector(selectAreasState, state =>
    (state.areas || []).filter(({ isConfigured }) => isConfigured),
  );

const makeSelectConfiguredAreasHavingConfiguredHotel = () =>
  createSelector(selectAreasState, state =>
    (state.areas || []).filter(
      ({ isConfigured, totalHotelConfigured }) =>
        isConfigured && totalHotelConfigured !== 0,
    ),
  );

const makeSelectUnconfiguredAreas = () =>
  createSelector(selectAreasState, state =>
    (state.areas || []).filter(
      ({ isConfigured, totalHotelConfigured }) =>
        !isConfigured || totalHotelConfigured === 0,
    ),
  );

export {
  makeSelectAreasLoading,
  makeSelectAreasError,
  makeSelectAreas,
  makeSelectConfiguredAreas,
  makeSelectUnconfiguredAreas,
  makeSelectConfiguredAreasHavingConfiguredHotel,
};
