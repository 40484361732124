import React from 'react';
import styled from 'styled-components';
import { Divider } from 'antd';
import tw from 'tailwind.macro';

const Container = styled.div`
  ${tw`flex items-center justify-between bg-grey-lightest px-8`};
  height: 56px;
`;

interface SecondNavHeaderProps {
  title: string;
  leftRender?: React.ReactNode;
  rightRender?: React.ReactNode;
}

const Title = styled.h2`
  ${tw`uppercase text-base font-semibold text-grey-darkest m-0`}
  letter-spacing: 0.015em;
`;

const StyledLeftRender = styled.div`
  ${tw`h-full flex items-center`}
`;

const StyledDivider = styled(Divider)`
  height: 100% !important;
  margin-left: 32px !important;
  margin-right: 32px !important;
`;

export default function SecondNavHeader({
  leftRender,
  rightRender,
  title,
}: SecondNavHeaderProps) {
  return (
    <Container>
      <div className="flex items-center h-full">
        <Title>{title}</Title>
        <div className="py-2 h-full">
          {leftRender && <StyledDivider type="vertical" />}
        </div>
        <StyledLeftRender>{leftRender}</StyledLeftRender>
      </div>
      {rightRender && <div>{rightRender}</div>}
    </Container>
  );
}
