import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import { Skeleton } from '@uxui/skeleton-loader';

const Header = styled.header`
  ${tw`bg-white px-8 flex items-center justify-between`};
  height: 56px;
`;

const HeaderLoader = () => {
  return (
    <Header>
      <div className="flex items-center">
        <Skeleton width="36px" height="36px" radius="999px" />
        <Skeleton width="80px" height="16px" className="ml-2" />
      </div>
      <div>
        <Skeleton width="24px" height="24px" radius="999px" />
      </div>
    </Header>
  );
};

export default HeaderLoader;
