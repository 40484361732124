import React, { Suspense, useEffect, useMemo } from 'react';
import { RouteComponentProps, Redirect } from '@reach/router';
import areaReducer from '../LocationManagementHome/reducer';
import areaSaga from '../LocationManagementHome/saga';

import dashboardReducer from './reducer';
import { ErrorBoundary } from '@aha/ui';
import { useInjectReducer } from 'utils/injectReducer';
import Header from './Header';
import saga from './saga';
import { useInjectSaga } from 'utils/injectSaga';
import ROUTES from 'constants/routes';
import { useDispatch } from 'react-redux';
import { setDashboardHotelId, fetchDashboardHotel } from './actions';
import { formatNestedRoutePath } from '@aha/utils';
import PageTableLoader from 'components/Loaders/PageTableLoader';

const mapDispatchToProps = (dispatch: any) => ({
  doSetDashboardHotelId: (hotelId: string) =>
    dispatch(setDashboardHotelId(hotelId)),
  doFetchDashboardHotel: (hotelID: any) =>
    dispatch(fetchDashboardHotel(hotelID)),
});

const Dashboard: React.FunctionComponent<RouteComponentProps> = ({
  children,
  location,
  path,
}) => {
  useInjectSaga({ key: 'dashboard', saga });
  useInjectReducer({ key: 'dashboard', reducer: dashboardReducer });
  useInjectReducer({ key: 'areas', reducer: areaReducer });

  const { pathname } = location!;
  const dispatch = useDispatch();
  const { doFetchDashboardHotel } = mapDispatchToProps(dispatch);
  const hotelRefCode = pathname.split('/')[2];
  const modifiedChildren = useMemo(
    // @ts-ignore
    () => formatNestedRoutePath(children, path),
    [children],
  );

  useEffect(() => {
    if (hotelRefCode) {
      doFetchDashboardHotel(hotelRefCode);
    }
  }, [hotelRefCode]);

  if (!hotelRefCode) {
    return <Redirect to={ROUTES.LOCATIONS} noThrow />;
  }

  // const { fetchCurrentUser, fetchLocations } = mapDispatchToProps(dispatch);

  // if (configuredLocations.length === 0) {
  //   return <Redirect to={ROUTES.CONFIGURATION} noThrow />;
  // }

  return (
    <ErrorBoundary>
      <Header />
      <Suspense fallback={<PageTableLoader />}>{modifiedChildren}</Suspense>
    </ErrorBoundary>
  );
};

export default Dashboard;
