import * as React from 'react';
import { Badge as AntBadge } from 'antd';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import { BadgeProps } from 'antd/lib/badge';

const StyledCountBagde = styled(AntBadge)<{
  custom?: { textColor: string; bgColor: string };
}>`
  .ant-badge-count {
    ${tw`flex items-center justify-center font-medium text-white`}
    font-size: 10px;
    min-width: 14px;
    height: 14px;
    padding: 0px 4px;
    animation: none !important;
  }
`;

const GreenCountBagde = styled(StyledCountBagde)`
  .ant-badge-count {
    background: var(--green);
  }
`;

const PrimaryCountBagde = styled(StyledCountBagde)`
  .ant-badge-count {
    background: var(--primary);
  }
`;

const RedCountBagde = styled(StyledCountBagde)`
  .ant-badge-count {
    background: var(--red);
  }
`;

const CustomColorBadge = styled(StyledCountBagde)`
  .ant-badge-count {
    background-color: ${props => !!props.custom && props.custom.bgColor};
    color: ${props => !!props.custom && props.custom.textColor};
  }
`;

const components: { [k in BadgeColor]: any } = {
  green: GreenCountBagde,
  red: RedCountBagde,
  primary: PrimaryCountBagde,
};

type BadgeColor = 'green' | 'red' | 'primary';

export interface CountBadgeProps extends BadgeProps {
  color?: BadgeColor;
  count?: number;
  offsetY?: number | string;
  up?: boolean;
  custom?: {
    textColor: string;
    bgColor: string;
  };
}

export const CountBadge: React.FC<CountBadgeProps> = ({
  color = 'red',
  count = 0,
  offsetY = 7,
  children,
  ...rest
}) => {
  const RenderCountBadge = rest.custom ? CustomColorBadge : components[color];

  let newOffset: [number | string, number | string] = [10, offsetY];
  if (count > 999) {
    newOffset = [19, offsetY];
  } else if (count > 99) {
    newOffset = [16, offsetY];
  } else if (count > 9) {
    newOffset = [13, offsetY];
  }

  return (
    <RenderCountBadge
      count={count}
      offset={newOffset}
      showZero
      overflowCount={999}
      {...rest}
    >
      {children}
    </RenderCountBadge>
  );
};

export default CountBadge;
