import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { logout } from 'containers/Auth/actions';
import { Link } from '@reach/router';
import ROUTES from 'constants/routes';
import { Logo, AvatarDropdown } from '@aha/ui';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import { createStructuredSelector } from 'reselect';
import { ApplicationRootState } from 'types/app';
import { ViewmodelUser } from 'types/rms-schema';
import { makeSelectCurrentUser } from 'containers/Auth/selectors';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  doLogout: () => dispatch(logout()),
});

const mapStateToProps = createStructuredSelector<
  ApplicationRootState,
  { user: ViewmodelUser | null }
>({
  user: makeSelectCurrentUser(),
});

const Header = styled.header`
  ${tw`px-8 flex items-center justify-between bg-white absolute pin-x pin-t`};
  min-width: 1200px;
  height: 56px;
`;

export interface GlobalHeaderProps {
  leftRender?: React.ReactNode;
  rightRender?: React.ReactNode;
  logoLink?: string;
}

export default function GlobalHeader({
  leftRender,
  rightRender,
  logoLink = ROUTES.HOME,
}: GlobalHeaderProps) {
  const dispatch = useDispatch();
  const { doLogout } = mapDispatchToProps(dispatch);
  const { user } = useSelector(mapStateToProps);

  return (
    <Header>
      <div className="flex items-center">
        <Link to={logoLink} className="text-2xl font-bold">
          <Logo hasText />
        </Link>

        {leftRender && <div className="ml-6">{leftRender}</div>}
      </div>

      <div className="flex items-center">
        {rightRender && <div className="mr-4">{rightRender}</div>}
        <AvatarDropdown
          name={user?.name || ''}
          email={user?.email || ''}
          avatar={user?.avatar}
          menu={[{ text: 'Profile' }]}
          logOut={doLogout}
        />
      </div>
    </Header>
  );
}
