import React from 'react';
import styled from 'styled-components';
import { SubHeading, Icon } from '@aha/ui';
import { Link } from '@reach/router';
import tw from 'tailwind.macro';

const Pane = styled.div`
  position: relative;
  width: calc(100% - 187px);
  padding: 16px;
  background-color: var(--white) !important;
  > div {
    height: 100%;
  }
`;

const Header = styled.header`
  ${tw`flex items-center justify-between absolute bg-white pin-x pin-t px-6`};
  z-index: 1;
  height: 56px;
`;

const Footer = styled.footer`
  ${tw`flex items-center justify-between absolute bg-white pin-x pin-b px-6 border-t border-grey-lighter`};
  height: 56px;
`;

const Body = styled.div`
  ${tw`absolute pin px-6`};
  padding-top: 70px;
  padding-bottom: 70px;
  overflow-y: auto;
`;

interface SiderPaneProps {
  children: React.ReactNode;
}

const SiderPane = ({ children }: SiderPaneProps) => {
  return <Pane>{children}</Pane>;
};

export interface SiderPaneHeaderProps {
  title: string;
  backLink?: string;
  rightExtra?: React.ReactNode;
}

export interface SiderPaneFooterProps {
  leftExtra?: React.ReactNode;
  rightExtra?: React.ReactNode;
}

SiderPane.Header = ({ title, backLink, rightExtra }: SiderPaneHeaderProps) => (
  <Header>
    <SubHeading>
      {backLink ? (
        <Link
          to={backLink}
          className="flex items-center text-grey-darker text-base"
        >
          <Icon type="chevron-left" className="text-secondary mr-3" />
          {title}
        </Link>
      ) : (
        title
      )}
    </SubHeading>
    {rightExtra && <div>{rightExtra}</div>}
  </Header>
);

SiderPane.Footer = ({ leftExtra, rightExtra }: SiderPaneFooterProps) => (
  <Footer>
    <div>{leftExtra}</div>
    {rightExtra && <div>{rightExtra}</div>}
  </Footer>
);

SiderPane.Body = ({ children }: { children: React.ReactNode }) => (
  <Body>{children}</Body>
);

export default SiderPane;
