import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types/app';
import { initialState } from './reducer';

const selectLocationManagementState = (state: ApplicationRootState) => {
  return state.locationManagement || initialState;
};

const makeSelectLocationManagementId = () =>
  createSelector(
    selectLocationManagementState,
    locationManagementState => locationManagementState.locationId,
  );

export { makeSelectLocationManagementId };
